import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Worm_Planetary.png';
import pa2 from '../assets/img/products/Worm-Planetary.jpeg';



function WormPlanetaryGearbox() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
        <Helmet>
                <title>GPRO |  Worm Planetary Gearbox </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <div className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Worm Planetary Gearbox</li>
                        </ol>
                    </nav>
                </div>
            </div>

<section className="product-desc graybg">
            <div className="container">
                <Tabs
                    defaultActiveKey="Description"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Description" title="Description">
                    <h2 className="inner-title mt-5 pb-3 text-start">Leading Worm Planetary Gearbox Manufacturers In India
                    </h2>
                    <div className="row">
                        <div className="col-md-4">
                        <img src={pa2} className="w-100" alt=" Worm Planetary Gearbox" />
                        </div>
                        <div className="col-md-8">
                            <p>Our company, GPRO drive has emerged out as a leading name in the business today.</p>

                            <p>The products we offer tend to please our clients incredibly. And with our worm planetary
                                gearbox, we continue pleasing our clients.</p>

                            <p>These categories of the planetary gearbox are fabricated using dimensionally accurate,
                                case hardened sun and planet gears, placed between large, anti-frictional bearings.</p>

                            <p>We offer the customers a wide and explicit range of worm gearboxesavailable in various
                                ratios, mountings with different input and output configurations and just to suit the
                                requirements of the clients.</p>
                        </div>
                    </div>
                    <h2 className="inner-title mt-5 pb-3 text-start">Features of these Worm Planetary Gearboxes</h2>
                    <div className="row">

                        <div className="col-md-8">
                            <p>Let us have a look at some of the product specifications and features of these
                                <span className="fw-bold">Worm Planetary Gearboxes:</span>
                            </p>
                            <ul className="list-dot">
                                <li>Show an optimum performance</li>
                                <li>Light weighed & highly reliable</li>
                                <li>A long lasting functional life</li>
                                <li>Support torque of range: 4kgm to 10000kgm</li>
                                <li>Ratio of 3:1 to 15000:1 & robustly constructed</li>
                                <li>The input power they require varies from fractional HP to 100 HP</li>
                                <li>Mountings of various sorts such as the foot, flange and agitator</li>
                                <li>Highly durable and possess a low maintenance cost</li>
                                <li>Offer highly efficient services & offer precise functionality</li>
                            </ul>

                        </div>
                        <div className="col-md-4">
                        <img src={pa1} className="w-100" alt=" Worm Planetary Gearbox" />
                        </div>
                    </div>
                    </Tab>
                    <Tab eventKey="Technical Specifications" title="Technical Specifications">
                    <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                    <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various
                        equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack,
                            Bevel Drives, Worm Drives,</span>
                        and etc.</p>
                    <div className="technicalinfo mt-4">
                        <div className="list">
                            <div className="info-head">
                                Mounting
                            </div>
                            <div className="info-desc">
                                Foot / Flange
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Input</div>
                            <div className="info-desc">Hollow to suite Electric / Hydraulic Motor<br/>
                                Male Free Shaft (For Mounting Coupling, Pulley, Sprocket etc.)</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Orientation</div>
                            <div className="info-desc">
                                B3 (Horizontal)<br/>
                                V5 (Vertically Downward)<br/>
                                V6 (Vertically Upward)
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Efficiency</div>
                            <div className="info-desc">58% per stage</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Ambient Temp.</div>
                            <div className="info-desc">40 Deg. C</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Allowable Temperature Rise</div>
                            <div className="info-desc">45 Deg. C above ambient temp.</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Ratio Range</div>
                            <div className="info-desc">From 27:1 Upto customer requirement</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Output Torque Range</div>
                            <div className="info-desc">From 12 Kg.m To 60,000 Kg.m</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Input Power</div>
                            <div className="info-desc">From 0.16 Hp/0.12kW To 500 Hp/375kW</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Max. Input Speed</div>
                            <div className="info-desc">1440 rpm (For Higher / Lower Speed Consult GPRO)</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Paint Shade</div>
                            <div className="info-desc">STD : RAL Blue<br/>
                                SPL : Special color can be provided against specific customer requirement</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Method of Cooling</div>
                            <div className="info-desc">STD : Splash Type Lubrication air cooled<br/>
                                SPL : Forced Cooling can be provided against specific customer requirement</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Lubrication</div>
                            <div className="info-desc">
                                For Model 501 Gearbox is filled with Grease<br/>
                                ISO VG 220/320 Synthetic Base Oil recomanded for other gearbox<br/>
                                STD : Gearbox supplied without oil<br/>
                                SPL : Gearbox supplied with oil against specific customer requirement
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Standard Accessories</div>
                            <div className="info-desc">For Orientation V5 & V6 Oil Cup Assembly will be provided</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Applicable Standards</div>
                            <div className="info-desc">AGAM , ISO, IS</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Advantage</div>
                            <div className="info-desc">Input & Output are at right angle to each other hence occupies less length, Low noise</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Disadvantage</div>
                            <div className="info-desc">Low Efficiency (60% for Single Stage, 45-50% for multistage Gearbox)</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Application</div>
                            <div className="info-desc">Material handling, Paper, Rubber, Food Industry, Automation, Textile</div>
                        </div>

                    </div>
                    </Tab>

                </Tabs>
            </div>
            </section>
        </>
    );
}

export default WormPlanetaryGearbox; 