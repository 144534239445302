import React from "react";
import { Link } from "react-router-dom";


function Sitemap() {
    return (
        <>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="list-dot sitemap">
                                    <li>
                                        <Link className="text-reset" to="/index">Home</Link>
                                    </li>


                                    <li>
                                        <a className="text-reset">  
                                            Products
                                        </a>
                                        <ul>
                                            <li><Link className="text-reset" to="/product/industrialgearbox">Industrial Gearbox</Link></li>
                                            <li><Link className="text-reset" to="/product/planetary-gearbox">Planetary Gearbox</Link></li>
                                            <li><Link className="text-reset" to="/product/wormPlanetarygearbox">Worm Planetary Gearbox</Link></li>
                                            <li><Link className="text-reset" to="/product/bevel-Planetary-gearbox">Bevel Planetary Gearbox</Link></li>
                                            <li><Link className="text-reset" to="/product/creep-drive">Creep Drive</Link></li>
                                            <li><Link className="text-reset" to="/product/track-drive">Track Drive</Link></li>
                                            <li><Link className="text-reset" to="/product/winch-drive">Winch Drive</Link></li>
                                            <li><Link className="text-reset" to="/product/electricwinch">Electric Winch</Link></li>
                                            <li><Link className="text-reset" to="/product/hydraulic_winch">Hydraulic Winch</Link></li>
                                            <li><Link className="text-reset" to="/product/screw_jack">Screw Jack</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/applications" className="text-reset">Applications</Link>
                                    </li>
                                    <li>
                                        <Link to="/download-catalouge" className="text-reset">Download Catalogue</Link>

                                    </li>

                                    <li>
                                        <Link to="/enquiry" className="text-reset">Enquiry</Link>
                                    </li>
                                    <li>
                                        <Link to="/contactus" className="text-reset">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/careers" className="text-reset">Careers</Link>

                                    </li>
                                    <li>
                                        <Link to="/contactus" className="text-reset">Site Map</Link>

                                    </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Sitemap; 