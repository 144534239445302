import React from "react";
import hero from './assets/img/home/hero.png';
import greabox from './assets/img/home/greabox.png'
import Winch from './assets/img/home/winch.png'
import Drive from './assets/img/home/drive.png'
import agriculture from './assets/img/home/agriculture.png'
import forestry from './assets/img/home/forestry.png'
import material from './assets/img/home/material.png'
import quality from './assets/img/quality.svg'
import mission from './assets/img/mission.svg'
import vision from './assets/img/vision.svg'
import Customer from "./customer";
import CountUp from 'react-countup';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { video } from 'video-react';
import { Helmet } from 'react-helmet'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay, EffectFade } from "swiper";


function Home() {
    const url = "https://secureservercdn.net/160.153.137.163/83a.5eb.myftpupload.com/wp-content/uploads/2021/12/GPRO-SHOWREEL-FOR-WEBSITE-01.mp4"
    const history = useNavigate()

    const goEnquiry = () => {
        history('/enquiry');
    }
    return (
        <>
            <Helmet>
                <title>GPRO | Home </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="hero">
                <div className="container">

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={70}
                        grabCursor={true}
                        effect={"fade"}
                        navigation={false}
                        modules={[Navigation, Autoplay, EffectFade]}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                        }}
                        className="mySwiper3"
                    >
                        <SwiperSlide>
                            <div className="row">
                                <div className="col-md-6 hero-data">
                                    <div className="hero-content">
                                        <h1>Bevel planetary gearbox</h1>
                                        <h4>we offer these gearboxes at a very affordable price as well.</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 hero-img">
                                    <img src={hero} alt="Hero" />
                                </div>
                            </div>
                        </SwiperSlide>

                        {/* <SwiperSlide>
                            <div className="row">
                                <div className="col-md-6 hero-data">
                                    <div className="hero-content">
                                        <h1>Bevel planetary gearbox</h1>
                                        <h4>we offer these gearboxes at a very affordable price as well.</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 hero-img">
                                    <img src={agriculture} alt="Hero" />
                                </div>
                            </div>
                        </SwiperSlide> */}

                    </Swiper>

                </div>
            </section>



            <section className="highlights">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <h4>Customers</h4>
                            {/* <div>600+</div> */}
                            {/* <CountUp end={600}  /> */}
                            <CountUp start={100} end={600} delay={0}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} /> <span>+</span>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4>Supplied</h4>
                            <CountUp start={2000} end={25000} delay={0}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} /> <span>+</span>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4>States</h4>
                            {/* <div>25</div> */}
                            <CountUp start={0} end={25} delay={0}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} /> <span>+</span>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                    </div>
                </div>
            </section>


            <section className="product-category">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card-product" style={{
                                backgroundImage: `url(${greabox})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundPosition: `bottom right`
                            }}>
                                <h4>Gearbox</h4>
                                <a className="arrow-btn"></a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card-product" style={{
                                backgroundImage: `url(${Winch})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundPosition: `bottom right`
                            }}>
                                <h4>Winch</h4>

                                <Link to="/product/winch-drive" className="arrow-btn"></Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card-product" style={{
                                backgroundImage: `url(${Drive})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundPosition: `bottom right`
                            }}>
                                <h4>Drive</h4>
                                <Link to="/product/creep-drive" className="arrow-btn"></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="video-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <video autoplay="" loop="" muted="" id="myVideo">
                                <source
                                    src="https://secureservercdn.net/160.153.137.163/83a.5eb.myftpupload.com/wp-content/uploads/2021/12/GPRO-SHOWREEL-FOR-WEBSITE-01.mp4"
                                    type="video/mp4" />
                            </video> */}
                            <video
                                autoplay="" loop="" muted="" id="myVideo"
                                // src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                src="https://secureservercdn.net/160.153.137.163/83a.5eb.myftpupload.com/wp-content/uploads/2021/12/GPRO-SHOWREEL-FOR-WEBSITE-01.mp4"
                                type="video/mp4"
                            />

                        </div>
                        {/* <div className="col-md-6">
                            <ul className="highlight-points">
                                <li>
                                    <div className="tile-icon">
                                        <img src={quality} alt="Quality" />
                                    </div>
                                    <div className="desc">
                                        <div className="title">Quality Control</div>
                                        <p className="desc">To achieve CUSTOMER SATISFACTION by providing unmatched QUALITY product
                                            through continuous improvement</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="tile-icon">
                                        <img src={mission} alt="mission" />
                                    </div>
                                    <div className="desc">
                                        <div className="title">Mission</div>
                                        <p className="desc">We will build VALUE based organization which will SATISFY employees ,
                                            supplier and customers by protecting ENVIRONMENT with emphasis on CLEANLINESS.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="tile-icon">
                                        <img src={vision} alt="vision" />
                                    </div>
                                    <div className="desc">
                                        <div className="title">Vision</div>
                                        <p className="desc">
                                            - To become known GLOBAL brand.<br />
                                            - To become most ADMIRED company<br />
                                            - To Social cause Contributor.
                                        </p>

                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="app-section">
                <div className="container">
                    <h2 className="home-title">Applications</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <img src={agriculture} className="w-100" alt="" />
                            <div className="app-desc">AGRICULTURE</div>
                        </div>
                        <div className="col-md-4">
                            <img src={forestry} className="w-100" alt="" />
                            <div className="app-desc">FORESTRY</div>
                        </div>
                        <div className="col-md-4">
                            <img src={material} className="w-100" alt="" />
                            <div className="app-desc">MATERIAL HANDLING</div>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <Link className="view-all" to="/applications">View All</Link>
                    </div>
                </div>
            </section>
            <section className="section-quote" onClick={goEnquiry}>
                <div className="container">
                    <h2 className="text-uppercase ">request a quote</h2>
                </div>
            </section>

            <Customer />
        </>
    );
}

export default Home; 