import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet'


function Enquiry() {
    useEffect(() => {
        document.title = 'GPRO | Enquiry';
      },[]);

    const [show, setShow] = useState(false)
    const [alert, setAlert] = useState(false)
    const [file, setFile] = useState({})
    console.log('File', file);

    const handalChangeFile = (e) =>{
        console.log('file =>', e.target.files[0])
        setFile(e.target.files[0]);
    }

    const onSubmitForm =  async (e) => {
        e.preventDefault()
        // const formData = new FormData(e.target),        
        //     formDataObj = Object.fromEntries(formData.entries())
        // await axios.post("http://localhost/gpro/api/enquiry.php",formDataObj ).then(res =>{
        //     if(res.status === 200){
        //         setAlert(false)
        //     }
        // })
    }

    const setVisibility = (e) => {
        e.preventDefault();
        let values = e.target.value;
        if(values === "Electric" && "Hydraulic"){
            setShow(true);
        } else {
            setShow(false);
        }     

    }

    return (
        <>
         <Helmet>
                <title>GPRO | Enquiry </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="inner-hero">
                <div className="container">
                    <h1>Enquiry</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Enquiry</li>
                        </ol>
                    </nav>
                </div>
            </section>

            {
    alert && 
    <Alert variant="success">
      <Alert.Heading>Enquiry Submit successfuly !!</Alert.Heading>
      <p>
       We have received your enquiry and would like to thank you for writing to us. ...
      </p>     
     
    </Alert>
}
            <section className="enq-form">
                <div className="container">
                    <div className="row " >
                        <div className="col-md-12">
                            <form onSubmit={onSubmitForm} >
                                <div className="row boredr-bg">
                                    <div className="col-md-3">
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Customer Name</Form.Label>
                                            <Form.Control type="text" name="cust_code" placeholder="Customer Name" />
                                        </Form.Group>

                                    </div>


                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Model Type</Form.Label>
                                            <Form.Control as="select" name='model_type' >
                                                <option value="">-Select-</option>
                                                <option value="Planetary Gearbox">Planetary Gearbox</option>
                                                <option value="Worm-Planetary Gearbox">Worm-Planetary Gearbox</option>
                                                <option value="Bevel-Planetary Gearbox">Bevel-Planetary Gearbox</option>
                                                <option value="Creep Drive">Creep Drive</option>
                                                <option value="Track Drive">Track Drive</option>
                                                <option value="Electric Winch">Electric Winch</option>
                                                <option value="Hydraulic Winch">Hydraulic Winch</option>
                                                <option value="Screw Jack">Screw Jack</option>
                                                <option value="Special Planetary Gearbox">Special Planetary Gearbox</option>
                                                <option value="Planetary Gearedmotor">Planetary Gearedmotor</option>
                                                <option value="Worm Gearbox">Worm Gearbox</option>
                                                <option value="Worm Gearedmotor">Worm Gearedmotor</option>
                                                <option value="Shaft Mounted Gearbox">Shaft Mounted Gearbox</option>
                                                <option value="Parallel Shaft Helical Gearbox">Parallel Shaft Helical Gearbox</option>
                                                <option value="In-line Helical Gearbox">In-line Helical Gearbox</option>
                                                <option value="Parallel Shaft Helical Gearedmotor">Parallel Shaft Helical Gearedmotor</option>
                                                <option value="In-line Helical Gearedmotor">In-line Helical Gearedmotor</option>
                                                <option value="Total Special Gearbox">Total Special Gearbox</option>
                                                <option value="Direct Model">Direct Model</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-1">
                                        <Form.Group className="mb-2" controlId="formBasicEmail">
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control type="text" name="enquiry_qty" placeholder="" />
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Expected Date</Form.Label>
                                            <Form.Control type="date" name="enquiry_date" placeholder="Expected Delivery Date" />
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-3">
                                        <div className="mb-4">
                                            <label className="form-label">Enquiry for</label>
                                            <Form.Group>
                                                <div className="">
                                                    <div className=" form-check-inline" style={{ 'marginRight': '0.5rem' }}>
                                                        <Form.Check
                                                            value="Model"
                                                            type="radio"
                                                            aria-label="radio 1"
                                                            label="Model"
                                                            id="1"
                                                            name='enquiry_type'
                                                        />
                                                    </div>


                                                    <div className=" form-check-inline" style={{ 'marginRight': '0.5rem' }}>
                                                        <Form.Check
                                                            value="Item"
                                                            type="radio"
                                                            aria-label="radio 2"
                                                            label="Item"
                                                            id="2"
                                                            name='enquiry_type'
                                                        />
                                                    </div>

                                                    <div className=" form-check-inline" style={{ 'marginRight': '0.5rem' }}>
                                                        <Form.Check
                                                            value="Sub-Assembly"
                                                            type="radio"
                                                            aria-label="radio 3"
                                                            label="Sub-Assembly"
                                                            id="3"
                                                            name='enquiry_type'

                                                        />
                                                    </div>

                                                </div>

                                            </Form.Group>

                                        </div>
                                    </div>
                                </div>
                                <br/>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr bgcolor="#e6e4df">
                                                        <th>Description</th>
                                                        <th>Value</th>
                                                        <th>Symbol</th>
                                                        <th>UOM</th>
                                                        <th>Description</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0" id="AJAX_ADDED_ITEMS_LIST">
                                                    <tr>
                                                        <td>Input Power</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="input" name="input" value="" /> */}
                                                            <Form.Control type="text" name="input" placeholder="" />
                                                        </td>
                                                        <td>P1</td>

                                                        <td>

                                                            <Form.Group >
                                                                <div className="col-md p-0">
                                                                    <div className=" form-check-inline">
                                                                        <Form.Check
                                                                            value="KW"
                                                                            type="radio"
                                                                            aria-label="radio 1"
                                                                            label="KW"
                                                                            id="KW"
                                                                            name="input_uom"
                                                                        />


                                                                    </div>
                                                                    <div className=" form-check-inline">
                                                                        <Form.Check
                                                                            value="HP"
                                                                            type="radio"
                                                                            aria-label="radio 2"
                                                                            label="HP"
                                                                            id="HP"
                                                                            name="input_uom"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>

                                                        </td>
                                                        <td rowSpan="2">Prime Mover Type</td>
                                                        <td rowSpan="2">
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="mover_type" onChange={setVisibility}>
                                                                    <option value="">-Select-</option>
                                                                    <option value="Electric">Electric</option>
                                                                    <option value="Hydraulic">Hydraulic</option>
                                                                    <option value="Pnumatic">Pnumatic</option>
                                                                    <option value="IC Engine">IC Engine</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td>Required Output Power</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            <Form.Control type="text" name="output" placeholder="" />
                                                            {/* <input type="text" className="form-control" id="output" name="output" value="" />*/}
                                                            </td>
                                                        <td>P2</td>
                                                        <td>

                                                            <div className="col-md p-0">
                                                                <Form.Group >
                                                                    <div className="col-md p-0">
                                                                        <div className=" form-check-inline">
                                                                            <Form.Check
                                                                                value="KW"
                                                                                type="radio"
                                                                                aria-label="radio 1"
                                                                                label="KW"
                                                                                id="KW"
                                                                                name="output_umo"
                                                                            />


                                                                        </div>
                                                                        <div className=" form-check-inline">
                                                                            <Form.Check
                                                                                value="HP"
                                                                                type="radio"
                                                                                aria-label="radio 2"
                                                                                label="HP"
                                                                                id="HP"
                                                                                name="output_umo"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Input Torque</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="rated_torque" name="rated_torque" value="" /> */}
                                                            <Form.Control type="text" name="rated_torque" placeholder="" />
                                                        </td>
                                                        <td>T1</td>
                                                        <td>N.m</td>
                                                        <td rowSpan="2">Daily Operating Hours</td>
                                                        <td rowSpan="2">
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="op_hrs">
                                                                    <option value="">-Select-</option>
                                                                    <option value="2">2</option>
                                                                    <option value="4">4</option>
                                                                    <option value="6">6</option>
                                                                    <option value="8">8</option>
                                                                    <option value="10">10</option>
                                                                    <option value="12">12</option>
                                                                    <option value="16">16</option>
                                                                    <option value="24">Continuous 24 Hrs.</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Required Output Torque</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="output_torque" name="output_torque" value="" /> */}
                                                            <Form.Control type="text" name="rated_torque" placeholder="" />
                                                        </td>
                                                        <td>T2</td>
                                                        <td>N.m</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Ratio</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="ratio" name="ratio" value="" / > */}
                                                            <Form.Control type="text" name="ratio" placeholder="" />

                                                        </td>
                                                        <td>i</td>
                                                        <td>-</td>
                                                        <td rowSpan="2">Number of Starts & Stops per hour</td>
                                                        <td rowSpan="2">
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="per_hrs">
                                                                    <option value="">-Select-</option>
                                                                    <option value="Less Than 30">Less Than 30</option>
                                                                    <option value="30">30</option>                                                                <option value="60">60</option>
                                                                    <option value="80">80</option>
                                                                    <option value="100+">100+</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Input Speed</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="input_speed" name="input_speed" value="" onChange="calOutput()" /> */}
                                                            <Form.Control type="text" name="input_speed" placeholder="" />
                                                        </td>
                                                        <td>n1</td>
                                                        <td>rpm</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Output Speed</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="output_speed" name="output_speed" value="" /> */}
                                                            <Form.Control type="text" name="output_speed" placeholder="" />
                                                        </td>
                                                        <td>n2</td>
                                                        <td>rpm</td>
                                                        <td rowSpan="2">Gear drive Type</td>
                                                        <td rowSpan="2">
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="gear_type">
                                                                    <option value="">-Select-</option>
                                                                    <option value="In-Line">In-Line</option>
                                                                    <option value="Right-Angle(Worm Planetary)">Right-Angle(Worm Planetary)</option>
                                                                    <option value="Right-Angle(Bevel Planetary)">Right-Angle(Bevel Planetary)</option>
                                                                    <option value="Worm">Worm</option>
                                                                    <option value="Bevel">Bevel</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Radial Load on Input Shaft</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="radial_ip_shaft" name="radial_ip_shaft" value="" /> */}
                                                            <Form.Control type="text" name="radial_ip_shaft" placeholder="" />

                                                        </td>
                                                        <td>FR1</td>
                                                        <td>N</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Load application Distance @ Input Shaft</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="load_ip_shaft" name="load_ip_shaft" value="" /> */}
                                                            <Form.Control type="text" name="load_ip_shaft" placeholder="" />
                                                        </td>
                                                        <td>X1</td>
                                                        <td>mm</td>
                                                        <td >Enviroment</td>
                                                        <td >
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="enviroment">
                                                                    <option value="">-Select-</option>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Dusty">Dusty</option>
                                                                    <option value="Humid">Humid</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Radial Load on Output Shaft</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="radial_op_shaft" name="radial_op_shaft" value="" /> */}
                                                            <Form.Control type="text" name="radial_op_shaft" placeholder="" />
                                                        </td>
                                                        <td>FR2</td>
                                                        <td>N</td>
                                                        <td >Orientation</td>
                                                        <td>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="orientation">
                                                                    <option value="">-select-</option>
                                                                    <option value="1">Horizontal</option><option value="2">Vertical Down</option><option value="3">Vertical Up</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Load application Distance @ Output Shaft</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="load_op_shaft" name="load_op_shaft" value="" /> */}
                                                            <Form.Control type="text" name="load_op_shaft" placeholder="" />
                                                        </td>
                                                        <td>X2</td>
                                                        <td>mm</td>
                                                        <td>Input Shaft</td>
                                                        <td>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="ip_shaft">
                                                                    <option value="">-Select-</option>
                                                                    <option value="Male Cylndrical">Male Cylndrical</option>
                                                                    <option value="Hollow">Hollow</option>
                                                                    <option value="Male/Female Spline">Male/Female Spline</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Thrust Load @ Input Shaft</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="t_ip_shaft" name="t_ip_shaft" value="" /> */}
                                                            <Form.Control type="text" name="t_ip_shaft" placeholder="" />
                                                        </td>
                                                        <td>FA1</td>
                                                        <td>N</td>
                                                        <td rowSpan="2">Mounting</td>
                                                        <td rowSpan="2">
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="mounting">
                                                                    <option value="">-Select-</option>
                                                                    <option value="1">Foot</option><option value="2">Flange</option><option value="3">Agitator</option><option value="4">Special</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Thrust Load @ Output Shaft</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="t_op_shaft" name="t_op_shaft" value="" /> */}
                                                            <Form.Control type="text" name="t_op_shaft" placeholder="" />
                                                        </td>
                                                        <td>FA2</td>
                                                        <td>N</td>

                                                    </tr>

                                                    <tr>
                                                        <td>Required Gearbox Life</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="req_time" name="req_time" value="" /> */}
                                                            <Form.Control type="text" name="req_time" placeholder="" />
                                                        </td>
                                                        <td>h</td>
                                                        <td>hours</td>
                                                        <td rowSpan="2">Output Shaft</td>
                                                        <td rowSpan="2">
                                                            <Form.Group className="mb-3" >
                                                                <Form.Control as="select" name="op_shaft">
                                                                    <option value="">-Select-</option>
                                                                    <option value="Male Cylndrical">Male Cylndrical</option>
                                                                    <option value="Hollow">Hollow</option>
                                                                    <option value="Male/Female Spline">Male/Female Spline</option>
                                                                    <option value="Shrink Disc">Shrink Disc</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Maximum Ambient Temperature</td>
                                                        <td style={{ 'width': '200px' }}>
                                                            {/* <input type="text" className="form-control" id="ambient_temperature" name="ambient_temperature" value="" /> */}
                                                            <Form.Control type="text" placeholder="" />
                                                        </td>
                                                        <td></td>
                                                        <td>Degree Celsius</td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            {show &&
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr align="center" bgcolor="#e6e4df">
                                                            <th colspan="3">Electric Prime Mover</th>
                                                            <th colspan="3">Hydraulic Prime Mover</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Nominal Power</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="nm_power" name="nm_power" value="" /> */}
                                                                <Form.Control type="text" name="nm_power" placeholder="" />
                                                            </td>
                                                            <td>

                                                                <div className="col-md p-0">
                                                                    <Form.Group >
                                                                        <div className="col-md p-0">
                                                                            <div className=" form-check-inline">
                                                                                <Form.Check
                                                                                    value="KW"
                                                                                    type="radio"
                                                                                    aria-label="radio 1"
                                                                                    label="KW"
                                                                                    id="KW"
                                                                                    name="nm_speed_uom"
                                                                                />


                                                                            </div>
                                                                            <div className=" form-check-inline">
                                                                                <Form.Check
                                                                                    value="HP"
                                                                                    type="radio"
                                                                                    aria-label="radio 2"
                                                                                    label="HP"
                                                                                    id="HP"
                                                                                    name="nm_speed_uom"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                            </td>
                                                            <td>Motor Type</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Control as="select" name="motor_type">
                                                                        <option value="">-Select-</option>
                                                                        <option value="Orbital">Orbital</option>
                                                                        <option value="Piston">Piston</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Nominal Speed</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="nm_speed" name="nm_speed" value=""> */}
                                                                <Form.Control type="text" name="nm_speed" placeholder="" />
                                                            </td>
                                                            <td>rpm</td>
                                                            <td>Subtype</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Control as="select" name="nm_speed_uom">
                                                                        <option value="">-Select-</option>
                                                                        <option value="Axial">Axial</option>
                                                                        <option value="Radial">Radial</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td>No.of Poles</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="no_poles" name="no_poles" value="" /> */}
                                                                <Form.Control type="text" name="no_poles" placeholder="" />
                                                            </td>
                                                            <td></td>
                                                            <td>Displacement</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="displacement" name="displacement" value="" /> */}
                                                                <Form.Control type="text" name="displacement" placeholder="" />
                                                            </td>
                                                            <td>CC</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Frame Size</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="framesize_id" name="framesize_id" value="" /> */}
                                                                <Form.Control type="text" name="framesize_id" placeholder="" />
                                                            </td>
                                                            <td>FS</td>
                                                            <td>Max. Pressure</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="max_pressure" name="max_pressure" value="" /> */}
                                                                <Form.Control type="text" name="max_pressure" placeholder="" />
                                                            </td>
                                                            <td>Bar</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Mounting</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                <Form.Control type="text" name="mounting" placeholder="" />
                                                                {/* <input type="text" className="form-control" id="mounting" name="mounting" value="" /> */}
                                                            </td>
                                                            <td></td>
                                                            <td>Operating Pressure</td>
                                                            <td style={{ 'width': '200px' }}>
                                                                {/* <input type="text" className="form-control" id="op_pressure" name="op_pressure" value="" /> */}
                                                                <Form.Control type="text" name="op_pressure" placeholder="" />
                                                            </td>
                                                            <td>Bar</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Motor Voltage</td>
                                                            {/* <td style={{'width': '200px'}}><input type="text" className="form-control" id="voltage" name="voltage" value="" /></td> */}
                                                            <td style={{ 'width': '200px' }}><Form.Control type="text" name="voltage" placeholder="" /></td>
                                                            <td>V</td>
                                                            <td>Max. Flow</td>
                                                            {/* <td style={{'width': '200px'}}><input type="text" className="form-control" id="max_flow" name="max_flow" value="" /></td> */}
                                                            <td style={{ 'width': '200px' }}><Form.Control type="text" name="max_flow" placeholder="" /></td>
                                                            <td>lpm</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Frequency</td>
                                                            {/* <td style={{'width': '200px'}}><input type="text" className="form-control" id="frequency" name="frequency" value="" /></td> */}
                                                            <td style={{ 'width': '200px' }}><Form.Control type="text" name="frequency" placeholder="" /></td>
                                                            <td>Hz</td>
                                                            <td>Operating Flow</td>
                                                            {/* <td style={{'width': '200px'}}><input type="text" className="form-control" id="op_flow" name="op_flow" value="" /></td> */}
                                                            <td style={{ 'width': '200px' }}><Form.Control type="text" name="op_flow" placeholder="" /></td>
                                                            <td>lpm</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Duty Type</td>
                                                            {/* <td style={{'width': '200px'}}><input type="text" className="form-control" id="duty" name="duty" value="" /></td> */}
                                                            <td style={{ 'width': '200px' }}><Form.Control type="text" name="duty" placeholder="" /></td>
                                                            <td>Hz</td>
                                                            <td>Operating Speed</td>
                                                            {/* <td style={{'width': '200px'}}><input type="text" className="form-control" id="op_speed" name="op_speed" value="" /></td> */}
                                                            <td style={{ 'width': '200px' }}> <Form.Control type="text" name="op_speed" placeholder="" /></td>
                                                            <td>rpm</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className="mb-4">
                                            <label className="form-label">IS GEARBOX REQUIRED WITH ELECTRIC MOTOR?</label>
                                            <Form.Group>
                                                <div className="">
                                                    <div className=" form-check-inline" style={{ 'marginRight': '0.5rem' }}>
                                                        <Form.Check
                                                            value="YES"
                                                            type="radio"
                                                            aria-label="radio 1"
                                                            label="Yes"
                                                            id="yes"
                                                            name='isreq'
                                                        />
                                                    </div>
                                                    <div className=" form-check-inline" style={{ 'marginRight': '0.5rem' }}>
                                                        <Form.Check
                                                            value="NO"
                                                            type="radio"
                                                            aria-label="radio 2"
                                                            label="NO"
                                                            id="no"
                                                            name='isreq'
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="mb-4">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="file" placeholder="Upload Resume" name="doc_upload" onChange={handalChangeFile} />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control as="textarea"  name="message" rows={3} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row text-right" >
                                <div className="col-lg-6 col-xs-12 d-grid">
                                    </div>
                                    <div className="col-lg-6 col-xs-12 d-grid">
                                        <button type="submit" className="btn btn-info block " size="lg" ><b>Submit</b>&nbsp;<i className="fa fa-file-o"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section >


        </>
    );
}

export default Enquiry; 