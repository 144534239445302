import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Track-Drive.jpeg';
import pa2 from '../assets/img/products/Track Drive-1.jpeg';



function TrackDrive() {
    useEffect(() => {
        document.title = 'GPRO | Track Drive';
    }, []);

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
         <Helmet>
                <title>GPRO | Track Drive </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <div className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Track Drive</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="product-desc graybg">
            <div className="container">
                <Tabs
                    defaultActiveKey="Description"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Description" title="Description">
                        <h2 className="inner-title mt-5 pb-3 text-start">Leading Track Drive Manufacturers In India

                        </h2>
                        <div className="row">
                            <div className="col-md-4">
                            <img src={pa1} className="w-100" alt="Track Drive" />
                            </div>
                            <div className="col-md-8">
                                <p>Our company provides its customers a wide and explicit range of <span className="fw-bold">track drives</span> that is very hard to find these days.</p>

                                <p>We understand the needs of our customers, which is why we are inclined towards offering them <span className="fw-bold">track drives</span> with unparalleled performance.</p>

                                <p>They find extensive use in excavators and agricultural equipment, owing to their durability and versatility.</p>

                                <p>They are further used in crawler applications for any mobile equipments such as cranes, drilling rigs, etc.</p>


                            </div>
                        </div>
                        <h2 className="inner-title mt-5 pb-3 text-start">Features of these Track Drive</h2>
                        <div className="row">

                            <div className="col-md-8">
                                <p>Let us have a look at some of the product specifications and features of these
                                    <span className="fw-bold">Track Drive:</span>
                                </p>
                                <ul className="list-dot">
                                    <li>Resistant to corrosion
                                    </li>
                                    <li>Compactly designed and dimensionally correct
                                    </li>
                                    <li>Highly durable with a long lasting functional life.
                                    </li>
                                    <li>Torque capacity is around 300Nm to 250000 Nm
                                    </li>
                                    <li>Ratio is around 5:1 to 300:1
                                    </li>
                                    <li>Support a radial load of the range: 10000 N to 500000 N
                                    </li>
                                    <li>Possess a Hydraulic track drive with a hydraulic parking brake.
                                    </li>
                                    <li>Transmission construction they possess is single, double or triples stage compact
                                        planetary system</li>
                                </ul>

                            </div>
                            <div className="col-md-4">
                            <img src={pa2} className="w-100" alt="Track Drive" />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Technical Specifications" title="Technical Specifications">
                        <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                        <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various
                            equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack,
                                Bevel Drives, Worm Drives,</span>
                            and etc.</p>


                        <div className="technicalinfo mt-4">
                            <div className="list">
                                <div className="info-head">
                                    Mounting
                                </div>
                                <div className="info-desc">
                                    Body
                                </div>
                            </div>
                            <div className="list">
                                <div className="info-head">Input</div>
                                <div className="info-desc">Hollow to suite Hydraulic Motor</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Orientation</div>
                                <div className="info-desc">
                                    B3 (Horizontal)
                                </div>
                            </div>
                            <div className="list">
                                <div className="info-head">Efficiency</div>
                                <div className="info-desc">96% per stage</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Ambient Temp.</div>
                                <div className="info-desc">40 Deg. C</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Allowable Temperature Rise</div>
                                <div className="info-desc">45 Deg. C above ambient temp.</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Ratio Range</div>
                                <div className="info-desc">3.75 to 140</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Output Torque Range</div>
                                <div className="info-desc">From 150 Kg.m To 550 Kg.m</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Input Power</div>
                                <div className="info-desc">Cartridge Axial Piston Hydraulic Motor, Flanged Axial Piston
                                    Hydraulic Motor, Hydraulic Orbit Motor</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Max. Input Speed</div>
                                <div className="info-desc">As per motor</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Paint Shade</div>
                                <div className="info-desc">STD : STD : RAL Blue + Black, Black<br />
                                    SPL : Special color can be provided against specific customer requirement</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Method of Cooling</div>
                                <div className="info-desc">STD : Splash Type Lubrication air cooled</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Lubrication</div>
                                <div className="info-desc">
                                    ISO VG 220/320 Synthetic Base Oil<br />
                                    STD : Gearbox supplied without oil<br />
                                    SPL : Gearbox supplied with oil against specific customer requirement
                                </div>
                            </div>
                            <div className="list">
                                <div className="info-head">Standard Accessories</div>
                                <div className="info-desc">Can be provided with splined Bolt for Sprocket Mounting, Supplied
                                    with Hydraulic released Parking Brakes</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Applicable Standards</div>
                                <div className="info-desc">AGAM , ISO, IS</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Advantage</div>
                                <div className="info-desc">Very Rugged design for heavy duty application</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Disadvantage</div>
                                <div className="info-desc">Nil</div>
                            </div>
                            <div className="list">
                                <div className="info-head">Application</div>
                                <div className="info-desc">Crawller, Pocklen, Road Construction Machinery</div>
                            </div>

                        </div>
                    </Tab>

                </Tabs>
            </div>
            </section>
        </>
    );
}

export default TrackDrive; 