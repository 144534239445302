import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet'


// const baseApp = "http://localhost:4600/api/";

function ContactUs() {

    const [show, setShow] = useState(false);
    const onFormSubmit = async (e) => {
        e.preventDefault()
        // const formData = new FormData(e.target),
        //     formDataObj = Object.fromEntries(formData.entries())
        // await axios.post("http://localhost/gpro.in/api/index.php", formDataObj).then(res => {
        //     if (res.status === 200) {
        //         setShow(false);
        //     }
        //}
        // );
    }


    return (
        <>

            <Helmet>
                <title>GPRO | ContactUs </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>

            <section className="inner-hero">
                <div className="container">

                    <h1>Contact Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>

                </div>
            </section>
            {
                show &&
                <Alert variant="success">
                    <Alert.Heading>Thanks for being awesome!</Alert.Heading>
                    <p>
                        We have received your message and would like to thank you for writing to us. ...
                    </p>

                </Alert>
            }
            <section className="-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <Form onSubmit={onFormSubmit} action={'http://localhost/gpro/api/index.php'}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Name" name="name" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Email" name="email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="Test" placeholder="Subject" name="subject" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control as="textarea" rows={3} placeholder="Message" name="message" />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <h2 className="inner-title mb-4 text-start">Get In Touch With Us</h2>
                            <p className="fw-bold mb-2">Contact Us</p>
                            <p className="m-0">+91 902-882-8728</p>
                            <p className="m-0 "><a href="mailto:gprodrives@yahoo.com"
                                className="">gprodrives@yahoo.com</a></p>
                            <p className="m-0"><a href="mailto:sales@gpro.in" className="">sales@gpro.in</a></p>
                            <p>&nbsp;</p>
                            <p className="fw-bold  mb-2">Our Address</p>
                            <p className="m-0">M63, Additional MIDC, </p>
                            <p className="m-0">Satara - 415 004,</p>
                            <p className="m-0">Maharashtra, India</p>
                        </div>
                    </div>

                </div>
            </section >
            <section className="map-section p-0">
                <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=M63,%20Additional%20MIDC,%20Satara%20-%20415%20004,%20Maharashtra,%20India&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </section>

        </>
    );
}

export default ContactUs; 