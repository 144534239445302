import React, { useRef, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from 'react-helmet'

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/pa-01.png';
import pa2 from '../assets/img/products/pa-02.png';
import pa3 from '../assets/img/products/pa-03.png';
import pa4 from '../assets/img/products/pa-04.png';
import pa5 from '../assets/img/products/pa-05.png';
import pa6 from '../assets/img/products/pa-06.png';
import pa7 from '../assets/img/products/pa-07.png';
import pa8 from '../assets/img/products/pa-08.png';
import pa9 from '../assets/img/products/pa-09.png';
import pa10 from '../assets/img/products/pa-10.png';
import pa11 from '../assets/img/products/pa-11.png';
import pa12 from '../assets/img/products/pa.png';
import pa from '../assets/img/products/Planetary/pa.png';




function IndustrialGearbox() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <Helmet>
                <title>GPRO | Industrial Gearbox </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Industrial Gearboxes</li>

                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-5">
                            <Swiper
                                style={{
                                    "--swiper-navigation-color": "#fff",
                                    "--swiper-pagination-color": "#fff",
                                }}
                                loop={true}
                                zoom={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[Thumbs, Zoom, EffectCube]}
                                className="mySwiper2"
                                cubeEffect={{
                                    shadow: true,
                                    slideShadows: true,
                                    shadowOffset: 20,
                                    shadowScale: 0.94,
                                }}
                            >
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa} />
                                    </div>
                                    <div className="caption">
                                        <h4>1. Mounting - Foot</h4>
                                        <p>Input: Hollow, Orientation : B3</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa2} />
                                    </div>
                                    <div className="caption">
                                        <h4>2. Mounting - Foot</h4>
                                        <p>Input: Hollow, Orientation : V5</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa3} />
                                    </div>
                                    <div className="caption">
                                        <h4>3. Mounting - Foot</h4>
                                        <p>Input: Hollow, Orientation : V6</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa4} />
                                    </div>
                                    <div className="caption">
                                        <h4>4. Mounting - Foot</h4>
                                        <p>Input: Solid Shaft, Orientation : B3</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa5} />
                                    </div>
                                    <div className="caption">
                                        <h4>5. Mounting - Foot</h4>
                                        <p>Input: Solid Shaft, Orientation : V5</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa6} />
                                    </div>
                                    <div className="caption">
                                        <h4>6. Mounting - Foot</h4>
                                        <p>Input: Solid Shaft, Orientation : V6</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa7} />
                                    </div>
                                    <div className="caption">
                                        <h4>7. Mounting - Flange</h4>
                                        <p>Input: Hollow, Orientation : B3</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa8} />
                                    </div>
                                    <div className="caption">
                                        <h4>8. Mounting - Flange</h4>
                                        <p>Input: Hollow, Orientation : V5</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa9} />
                                    </div>
                                    <div className="caption">
                                        <h4>9. Mounting - Flange</h4>
                                        <p>Input: Hollow, Orientation : V6</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa10} />
                                    </div>
                                    <div className="caption">
                                        <h4>10. Mounting - Flange</h4>
                                        <p>Input: Solid Shaft, Orientation : B3</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa11} />
                                    </div>
                                    <div className="caption">
                                        <h4>11. Mounting - Flange</h4>
                                        <p>Input: Solid Shaft, Orientation : V5</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa12} />
                                    </div>
                                    <div className="caption">
                                        <h4>12. Mounting - Flange</h4>
                                        <p>Input: Solid Shaft, Orientation : V6</p>
                                    </div>
                                </SwiperSlide>


                            </Swiper>

                        </div>
                        <div className="col-md-7">
                            <div className="product-shortinfo">
                                <h4>Gearbox</h4>
                                <h1>INDUSTRIAL GEARBOX</h1>
                                <h5 className="mt-4">Different Models</h5>
                                <p>The products we offer are durable with a long lasting functional life. The quality of raw materials we use for manufacturing these products are par excellence, ensuring products with optimum quality.</p>
                            </div>
                            <div >
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    loop={true}
                                    spaceBetween={10}
                                    slidesPerView={0}
                                    // freeMode={true}
                                    // watchSlidesProgress={true}
                                    modules={[Thumbs]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa1} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa2} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa3} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa4} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa5} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa6} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa7} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa8} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa9} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa10} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa11} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa12} />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>



                </div>

            </section>


            <section className="product-desc graybg">
                <div className="container">
                    <Tabs
                        defaultActiveKey="Description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Description" title="Description">
                            <h2 className="inner-title mt-5 pb-3 text-start">Leading Industrial Gearbox Manufacturers In India</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={pa} className="w-100" alt="Industrial Gearbox" />
                                </div>
                                <div className="col-md-8">
                                    <p>Our company is quite a name in manufacturing <span className="fw-bold">planetary
                                        gearbox</span> for satisfying the
                                        requirements of our customers.
                                    </p>
                                    <p>They find extensive use in industrial applications operated by electric and hydraulic
                                        motors.
                                    </p>
                                    <p>We bestow our customers with superlative quality, cost-effective, power-saving <span
                                        className="fw-bold">planetary
                                        gearboxes</span> serving their objectives. These <span className="fw-bold">planetary
                                            reduction gearboxes</span> comprise
                                        internally toothed ring gears, planets mounted on planet carrier, and the sun gear.
                                    </p>
                                    <p>The reduced output speed is measured from the planet carrier.
                                    </p>
                                    <p>The output shaft has the same direction as the input.
                                    </p>
                                    <p>These gearboxes, owing to their exclusiveness are widely used in plants such as steel and
                                        sugar plants.</p>
                                </div>
                            </div>
                            <h2 className="inner-title mt-5 pb-3 text-start">Features of these Planetary Gearboxes</h2>
                            <div className="row">

                                <div className="col-md-8">
                                    <p>Let us have a look at some of the product specifications and features of these
                                        <span className="fw-bold">planetary gearboxes:</span>
                                    </p>
                                    <ul className="list-dot">
                                        <li>ISO 9001:2015 Certified Company.</li>
                                        <li>Certifying Agency : TUV Nord.</li>
                                        <li>Quality is way of life at GPRO Drives and the certification body helps us to make
                                            sure that we deliver value to the customer. </li>
                                        <li>Inward, In-process & Outward Inspections are perfectly mapped to provide desired
                                            output with the help of training and technology.</li>
                                        <li>All important machining processes are carried out in the house.</li>
                                        <li>Internal quality audits are carried out by trained in-house staff.</li>
                                        <li>Quality control delivers the reliable products to the customer.</li>
                                        <li>Component traceability is guaranteed throughout the process cycle.</li>
                                        <li>Final test is performed on 100% of products before dispatching to the customer.</li>
                                    </ul>

                                </div>
                                <div className="col-md-4">
                                    <img src={pa6} className="w-100" alt="Industrial Gearbox" />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Technical Specifications" title="Technical Specifications">
                            <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                            <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various
                                equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack,
                                    Bevel Drives, Worm Drives,</span>
                                and etc.</p>
                            <div className="technicalinfo mt-4">
                                <div className="list">
                                    <div className="info-head">
                                        Mounting
                                    </div>
                                    <div className="info-desc">
                                        Foot / Flange/ Agitator
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Input</div>
                                    <div className="info-desc">Hollow to suite Electric / Hydraulic Motor<br />
                                        Solid shaft (For Mounting Coupling, Pulley, Sprocket etc.) </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Orientation</div>
                                    <div className="info-desc">
                                        B3 (Horizontal)<br />
                                        V5 (Vertically Downward)<br />
                                        V6 (Vertically Upward)
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Efficiency</div>
                                    <div className="info-desc">Efficiency 96% per stage</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ambient Temp.</div>
                                    <div className="info-desc">Ambient Temp. 40 Deg. C</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Allowable Temperature Rise</div>
                                    <div className="info-desc">45 Deg. C above ambient temp.</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ratio Range</div>
                                    <div className="info-desc">From 3.63:1 Upto customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Output Torque Range</div>
                                    <div className="info-desc">From 12 Kg.m To 60,000 Kg.m</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Input Power</div>
                                    <div className="info-desc">From 0.16 Hp/0.12kW To 500 Hp/375kW</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Max. Input Speed</div>
                                    <div className="info-desc">1440 rpm (For Higher / Lower Speed Consult GPRO)</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Paint Shade</div>
                                    <div className="info-desc">STD : RAL Blue<br />
                                        SPL : Special color can be provided against specific customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Method of Cooling</div>
                                    <div className="info-desc">STD : Splash Type Lubrication air cooled<br />
                                        SPL : Forced Cooling can be provided against specific customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Lubrication</div>
                                    <div className="info-desc">
                                        For Model 501 Gearbox is filled with Grease<br />
                                        ISO VG 220/320 Synthetic Base Oil<br />
                                        STD : Gearbox supplied without oil<br />
                                        SPL : Gearbox supplied with oil against specific customer requirement
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Standard Accessories</div>
                                    <div className="info-desc">For Orientation V5 & V6 Oil Cup Assembly will be provided</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Applicable Standards</div>
                                    <div className="info-desc">AGAM , ISO, IS</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Advantage</div>
                                    <div className="info-desc">High Efficiency, Inline, High Torque Density, High Torque to weight
                                        ratio</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Disadvantage</div>
                                    <div className="info-desc">Noise level in some case will be upto 70dB</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Application</div>
                                    <div className="info-desc">Material handling, Chemical, Sugar, Cement,Paper, Rubber, Marine,
                                        Food Industry, Automation, Oil & Gas, Textile and much more</div>
                                </div>

                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </section>
        </>
    );
}

export default IndustrialGearbox; 