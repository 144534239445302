import React, {useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet'

import donwload from './assets/img/download-thumb.png';
import donwloadsvg from './assets/img/download.svg';


function Catalouge() {

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('/download-pdf/Catalogue-Planetary.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = '/download-pdf/Catalogue-Planetary.pdf';
                alink.click();
            })
        })
    }
    return (
        <>
         <Helmet>
                <title>GPRO | Catalogue </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="inner-hero">
                <div className="container">
                    <h1>Download Catalouge</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Download Catalogue</li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="app-innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 thumbnail-img border-img">
                            <div className="content-overlay"></div>
                            <img src={donwload} className="img-fluid content-image borderimg" />
                            <div className="content-details fadeIn-bottom">
                                <a onClick={onButtonClick} target="_blank">
                                    <img src={donwloadsvg} alt="Zoom" className="zoom-icon" />
                                </a>
                                <h3 className="content-title">Planetary Gearbox Catalogue</h3>
                            </div>
                        </div>
                        <div className="col-sm-3 thumbnail-img border-img">
                            <div className="content-overlay"></div>
                            <img src={donwload} className="img-fluid content-image borderimg" />
                            <div className="content-details fadeIn-bottom">
                                <a onClick={onButtonClick} target="_blank">
                                    <img src={donwloadsvg} alt="Zoom" className="zoom-icon" />
                                    </a>
                                <h3 className="content-title">Bevel Planetary Gearbox Catalogue</h3>
                            </div>
                        </div>
                        <div className="col-sm-3 thumbnail-img border-img">
                            <div className="content-overlay"></div>
                            <img src={donwload} className="img-fluid content-image borderimg" />
                            <div className="content-details fadeIn-bottom">
                                <a onClick={onButtonClick} target="_blank">
                                    <img src={donwloadsvg} alt="Zoom" className="zoom-icon" />
                                    </a>
                                <h3 className="content-title">Industrial Gearbox Catalogue</h3>
                            </div>
                        </div>
                        <div className="col-sm-3 thumbnail-img border-img">
                            <div className="content-overlay"></div>
                            <img src={donwload} className="img-fluid content-image borderimg" />
                            <div className="content-details fadeIn-bottom">
                                <a onClick={onButtonClick} target="_blank">
                                    <img src={donwloadsvg} alt="Zoom" className="zoom-icon" />
                                    </a>
                                <h3 className="content-title">Worm Planetary Gearbox Catalogue</h3>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>



    );
}

export default Catalouge; 