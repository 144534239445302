import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/img/logo.svg';
import { Link } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function Header() {
    return (
        <>
            <header>
                <Navbar collapseOnSelect expand="lg" bg="blank" className="navbar navbar-inverse navbar-fixed-top bs-docs-nav" role="banner">
                <Container>
                        <Navbar.Brand as={Link} to="/">
                            <a className="navbar-brand" as={Link} to="/">
                                <img src={logo} alt="Logo" />
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                            <Nav >
                                <Nav.Link as={Link} to="/home"  key="13">Home</Nav.Link>
                                <Nav.Link as={Link} to="/aboutus"  key="14">About Us</Nav.Link>
                                <NavDropdown title="Products" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/product/industrialgearbox"  key="15">Industrial Gearbox</NavDropdown.Item>
                                    <NavDropdown.Item  as={Link} to="/product/planetary-gearbox" key="1">Planetary Gearbox</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/wormPlanetarygearbox"  key="2">Worm Planetary Gearbox</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/bevel-Planetary-gearbox" key="3">Bevel Planetary Gearbox</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/creep-drive" key="3">Creep Drive</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/track-drive"  key="4">Track Drive</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/winch-drive"  key="5">Winch Drive</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/electricwinch"  key="6">Electric Winch</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/hydraulic_winch"  key="7">Hydraulic Winch</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/product/screw_jack"  key="8">Screw Jack</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link as={Link} to="/applications"  key="9">Applications</Nav.Link>
                                <Nav.Link as={Link} to="/enquiry"  key="10">Enquiry</Nav.Link>                                
                                <Nav.Link as={Link} to="/careers"  key="12">Careers</Nav.Link>
                                <Nav.Link as={Link} to="/contactus"  key="11">Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        </Container>
                </Navbar>

            </header>
        </>
    );
}

export default Header; 