import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Electric-Winch-08.jpeg';
import pa2 from '../assets/img/products/Electric-Winch-05.jpeg';
import pa3 from '../assets/img/products/Electric-Winch-06.jpeg';
import pa4 from '../assets/img/products/Electric-Winch-04-1-1.jpeg';
import pa5 from '../assets/img/products/Electric-WInch.jpeg';



function ElectricWinch() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
        <Helmet>
                <title>GPRO | Electric Winch </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Electric winch</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-5">
                            <Swiper
                                style={{
                                    "--swiper-navigation-color": "#fff",
                                    "--swiper-pagination-color": "#fff",
                                }}
                                loop={true}
                                zoom={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[Thumbs, Zoom, EffectCube]}
                                className="mySwiper2"
                                cubeEffect={{
                                    shadow: true,
                                    slideShadows: true,
                                    shadowOffset: 20,
                                    shadowScale: 0.94,
                                }}
                            >
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa1} />
                                    </div>
                                    <div className="caption">
                                    <h4>1. ELECTRIC Winch</h4>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa2} />
                                    </div>
                                    <div className="caption">
                                    <h4>1. ELECTRIC Winch</h4>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa3} />
                                    </div>
                                    <div className="caption">
                                    <h4>1. ELECTRIC Winch</h4>
                                    </div>
                                </SwiperSlide>


                            </Swiper>

                        </div>
                        <div className="col-md-7">
                            <div className="product-shortinfo">
                                <h4>Gearbox</h4>
                                <h1>Electric Winch</h1>
                                <h5 className="mt-4">Different Models</h5>
                                <p>The products we offer are durable with a long lasting functional life. The quality of raw materials we use for manufacturing these products are par excellence, ensuring products with optimum quality.</p>
                            </div>
                            <div >
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    loop={true}
                                    spaceBetween={10}
                                    slidesPerView={0}
                                    // freeMode={true}
                                    // watchSlidesProgress={true}
                                    modules={[Thumbs]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa1} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa2} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa3} />
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                        </div>
                    </div>



                </div>

            </section>


            <section className="product-desc graybg">
                <div className="container">
                    <Tabs
                        defaultActiveKey="Description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Description" title="Description">
                        <h2 className="inner-title mt-5 pb-3 text-start">Leading Electric Winch Manufacturers In India</h2>
                    <div className="row">
                        <div className="col-md-4">
                        <img src={pa4} className="w-100" alt="Electric Winch" />
                        </div>
                        <div className="col-md-8">
<p>Our company excels in manufacturing <span className="fw-bold">electric winches</span>.
</p><p>They are fitted with an electric motor.
</p><p>Technically, an <span className="fw-bold">electric winch</span> can be said a lifting device consisting of a horizontal cylinder turned by a crank on which a rope or cable winds.
</p><p>Designed especially for the purpose of serving applications where space is crucial and important, these winches are best suitable.
</p><p>Further, for smoother operation, they work on heat-treated sintered iron gears enhancing smoother operations.
</p>                        </div>
                    </div>
                    <h2 className="inner-title mt-5 pb-3 text-start">Features of these Electric Winches</h2>
                    <div className="row">

                        <div className="col-md-8">
                            <p>Let us have a look at some of the product specifications and features of these
                                <span className="fw-bold">Electric Winch:</span>
                            </p>
                            <ul className="list-dot">
                                <li>Consist of a precision needle bearing for high load areas offering lower friction level and longer life
                                </li><li>Possess an emergency crank handle
                                </li><li>Comprise of a high impact case providing weather resistance and further protect the internal components.
                                </li><li>Durable hook safety snap keeping the loads attached,available in varies AC and DC models
                                </li><li>Comprise of a superior stainless steel front plate providing high resistance to corrosion</li>
                            </ul>

                        </div>
                        <div className="col-md-4">
                        <img src={pa5} className="w-100" alt="Electric Winch" />
                        </div>
                    </div>
                        </Tab>
                        <Tab eventKey="Technical Specifications" title="Technical Specifications">
                        <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                    <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various
                        equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack,
                            Bevel Drives, Worm Drives,</span>
                        and etc.</p>
                    <div className="technicalinfo mt-4">
                        <div className="list">
                            <div className="info-head">
                                Mounting
                            </div>
                            <div className="info-desc">
                                Foot
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Input</div>
                            <div className="info-desc">Hollow to suite Electric Motor</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Orientation</div>
                            <div className="info-desc">
                                B1 (Base Mounted)<br/>
W1 (Wall Mounted)<br/>
C1 (Ceiling Mounted)
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Type of Operation</div>
                            <div className="info-desc">Lifting, Pulling</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Ambient Temp.</div>
                            <div className="info-desc">40 Deg. C</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Allowable Temperature Rise</div>
                            <div className="info-desc">45 Deg. C above ambient temp.</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Capacity Range</div>
                            <div className="info-desc">From 50 Kg To 50,000 Kg</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Speed Range</div>
                            <div className="info-desc">From 1 m/min To 20 m/min</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Wire Rope OD</div>
                            <div className="info-desc">From Ø6 mm to Ø40mm</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Wire Rope Length</div>
                            <div className="info-desc">From 10m to 1000m</div>
                        </div>
                        <div className="list">
                            <div className="info-head">No of wire rope falls</div>
                            <div className="info-desc">1 to 4</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Wire Rope Construction</div>
                            <div className="info-desc">Steel Core, Fibre Core</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Drum</div>
                            <div className="info-desc">
                                Single Grooved Drum, Double Grooved Drum
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Brake</div>
                            <div className="info-desc">Brakeing through Electromagnetic DC Brake</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Wire Rope Type</div>
                            <div className="info-desc">Galvanised, Ungalvanised</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Input Power</div>
                            <div className="info-desc">From 0.16 Hp/0.12kW<br/>
                                To 50 Hp/37.5kW</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Electric Control Panel</div>
                            <div className="info-desc">Panel with MCB, Single Phase Preventor, Contactor,<br/>
                                Pendant Type Swithch can be provided<br/>
                                Remote control operated Panel can also be provided</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Max. Input Speed</div>
                            <div className="info-desc">1440 rpm (For Higher / Lower Speed Consult GPRO)</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Paint Shade</div>
                            <div className="info-desc">STD : RAL Blue<br/>
                                SPL : Special color can be provided against specific customer requirement</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Method of Cooling</div>
                            <div className="info-desc">STD : Splash Type Lubrication air cooled</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Lubrication</div>
                            <div className="info-desc">
                                ISO VG 220/320 Synthetic Base Oil<br/>
STD : Winch supplied without oil<br/>
SPL : Winch supplied with oil against specific customer requirement
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Standard Accessories</div>
                            <div className="info-desc">Nill</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Applicable Standards</div>
                            <div className="info-desc">AGAM , ISO, IS</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Advantage</div>
                            <div className="info-desc">High Efficiency, Inline, High Torque Density, High Torque to weight ratio</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Disadvantage</div>
                            <div className="info-desc">Noise level in some case will be upto 70dB</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Application</div>
                            <div className="info-desc">Material handling, Chemical, Sugar, Cement,Paper, Rubber, Marine, Food Industry, Automation, Oil & Gas, Textile and much more</div>
                        </div>

                    </div>
                        </Tab>

                    </Tabs>
                </div>
            </section>
        </>
    );
}

export default ElectricWinch; 