import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Hydraulic-Winch-big.jpeg';
import pa2 from '../assets/img/products/Hydraulic-WInch.jpeg';



function HydraulicWinch() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
          <Helmet>
                <title>GPRO | Hydraulic Winch </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <div className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Hydraulic Winch</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="product-desc graybg">
                <div className="container">
                    <Tabs
                        defaultActiveKey="Description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Description" title="Description">
                            <h2 className="inner-title mt-5 pb-3 text-start">Leading Planetary Gearbox Manufacturers In India</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={pa1} className="w-100" alt="" />
                                </div>
                                <div className="col-md-8">
                                    <p>Backed by skilled and laborious professionals, our company expertise in manufacturing <span className="fw-bold">hydraulic winches</span>.</p>
                                    <p>These winches are inbuilt with disk brake, manual clutch option, and various others.</p>
                                    <p>Fused with high capacity and immaculate design, these winches are quite a favorite among our treasured customers.</p>
                                    <p>They are widely used in various applications such as mobile cranes, drilling rigs, offshore cranes, stackers, material handling, and so on.</p>
                                    <p>Further, these winches are accurately designed with much precise results.</p>
                                </div>
                            </div>
                            <h2 className="inner-title mt-5 pb-3 text-start">Features of these Hydraulic Winch</h2>
                            <div className="row">

                                <div className="col-md-8">
                                    <p>Let us have a look at some of the product specifications and features of these
                                        <span className="fw-bold">Hydraulic Winch:</span>
                                    </p>
                                    <ul className="list-dot">
                                        <li>Easy to install and operate</li>
                                        <li>Open design facilitating easy maintenance</li>
                                        <li>Possess a step less speed control</li>
                                        <li>Possess a powerful display that shows load, speed and pulling length</li>
                                        <li>Possesses an auto cut off feature when set to designated speed/ load</li>
                                        <li>Suitable for rough conditions owing to robust design</li>
                                        <li>Further loaded with mechanical anchors</li>
                                    </ul>

                                </div>
                                <div className="col-md-4">
                                    <img src={pa2} className="w-100" alt="" />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Technical Specifications" title="Technical Specifications">
                            <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                            <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various
                                equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack,
                                    Bevel Drives, Worm Drives,</span>
                                and etc.</p>
                            <div className="technicalinfo mt-4">
                                <div className="list">
                                    <div className="info-head">
                                        Foot
                                    </div>
                                    <div className="info-desc">
                                        Foot
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Input</div>
                                    <div className="info-desc">A. Hollow to suite Hydraulic Motor</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Orientation</div>
                                    <div className="info-desc">
                                        B1 (Base Mounted)<br />
                                        W1 (Wall Mounted)<br />
                                        C1 (Ceiling Mounted)
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Type of Operation</div>
                                    <div className="info-desc">Lifting, Pulling</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ambient Temp.</div>
                                    <div className="info-desc">40 Deg. C</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Allowable Temperature Rise</div>
                                    <div className="info-desc">45 Deg. C above ambient temp.</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Capacity Range</div>
                                    <div className="info-desc">From 50 Kg To 50,000 Kg</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Speed Range</div>
                                    <div className="info-desc">From 10 m/min To 60 m/min</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Wire Rope OD</div>
                                    <div className="info-desc">From Ø6 mm to Ø40mm</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Wire Rope Length</div>
                                    <div className="info-desc">From 10m to 1000m</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">No of wire rope falls</div>
                                    <div className="info-desc">1 to 4</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Wire Rope Construction</div>
                                    <div className="info-desc">Steel Core, Fibre Core</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Drum</div>
                                    <div className="info-desc">
                                        Single Grooved Drum, Double Grooved Drum
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Brake</div>
                                    <div className="info-desc">Brakeing through Multidisc Clutch Brake Arrangement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Wire Rope Type</div>
                                    <div className="info-desc">Galvanised, Ungalvanised</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">System Pressure Requirement</div>
                                    <div className="info-desc">From 100 Bar<br />
                                        To 250 Bar</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">System Flow Requirement</div>
                                    <div className="info-desc">From 20 lpm<br />
                                        To 150 lpm</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Max. Input Speed</div>
                                    <div className="info-desc">1000 rpm (For Higher / Lower Speed Consult GPRO)</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Paint Shade</div>
                                    <div className="info-desc">STD : RAL Blue<br />
                                        SPL : Special color can be provided against specific customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Method of Cooling</div>
                                    <div className="info-desc">STD : Splash Type Lubrication air cooled</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Lubrication</div>
                                    <div className="info-desc">
                                        ISO VG 220/320 Synthetic Base Oil<br />
                                        STD : Winch supplied without oil<br />
                                        SPL : Winch supplied with oil against specific customer requirement
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Standard Accessories</div>
                                    <div className="info-desc">Nill</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Applicable Standards</div>
                                    <div className="info-desc">AGAM , ISO, IS</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Advantage</div>
                                    <div className="info-desc">High Efficiency, Inline, High Torque Density, High Torque to weight ratio</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Disadvantage</div>
                                    <div className="info-desc">Nil</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Application</div>
                                    <div className="info-desc">Material handling, Chemical, Sugar, Cement,Paper, Rubber, Marine, Food Industry, Automation, Oil & Gas, Textile and much more</div>
                                </div>

                            </div>
                        </Tab>

                    </Tabs>
                </div>
                </section>
        </>
    );
}

export default HydraulicWinch; 