import './App.css';
import Footer from './layout/Footer';
import Header from './layout/header';
import { Route, Routes, Router } from "react-router-dom";
import Aboutus from "./AboutUs";
import Home from "./Home";
import Planetary from './Products/PlanetaryGearbox';
import Enquiry from './enquiry'
import ContactUs from './contactUs';
import Careers from './careers';
import Applications from './application';
import Catalouge from './catalouge';
import BevelPlanetarygearbox from './Products/bevel-planetary-gearbox';
import HydraulicWinch from './Products/hydraulic_winch';
import Screwjack from './Products/screw_jack';
import CreepDrive from './Products/creep-drive';
import TrackDrive from './Products/track_drive';
import WormPlanetaryGearbox from './Products/worm-planetary-gearbox';
import IndustrialGearbox from './Products/industrial-gearbox';
import WinchDrive from './Products/winch-drive';
import ElectricWinch from './Products/electric_winch';
import Sitemap from './sitemap';

function App() {
  return (
    <>
    <Header />       
       
            <Routes>
                <Route index element={<Home />} />
                <Route path="/home"element ={<Home />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/product/planetary-gearbox" element={<Planetary />} />
                <Route path="/product/industrialgearbox" element={<IndustrialGearbox />} />
                <Route path='/product/bevel-Planetary-gearbox' element={<BevelPlanetarygearbox/>} />
                <Route path='/product/hydraulic_winch' element={<HydraulicWinch/>} />
                <Route path='/product/winch-drive' element={<WinchDrive/>} />
                <Route path='/product/electricwinch' element={<ElectricWinch/>} />
                <Route path='/product/screw_jack' element={<Screwjack/>} />
                <Route path='/product/track-drive' element={<TrackDrive/>} />
                <Route path='/product/creep-drive' element={<CreepDrive/>} />
                <Route path='/product/wormPlanetarygearbox' element={<WormPlanetaryGearbox/>} />
                <Route path="/enquiry" element={<Enquiry />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/applications" element={<Applications />} />
                <Route path="/download-catalouge" element={<Catalouge />} />
                <Route path="/sitemap" element={<Sitemap />} />
            </Routes>
       
      <Footer />
    </>
  );
}

export default App;
