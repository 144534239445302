import React from "react";
import { Helmet } from 'react-helmet'


import Mayur from './assets/img/aboutus/MayurSir1.png';
import company from './assets/img/aboutus/companyimage.png';
import ISO from './assets/img/aboutus/ISO-img.png';
import team from './assets/img/aboutus/team.png';
import quality from './assets/img/quality.svg'
import mission from './assets/img/mission.svg'
import vision from './assets/img/vision.svg'


function Aboutus() {

    return (
        <>
            <Helmet>
                <title>GPRO | About us</title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="inner-hero">
                <div className="container">
                    <h1>About Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="ceo-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <p><span className="fw-bold">I Mayur Prakash Pawar</span> on behalf of GPRO Drives family thank you for giving us opportunity to
                                serve you.</p>

                            <p>Its been our <span className="fw-bold">15th year in manufacturing</span>. Without your support, we could not have come so far. 15
                                years of journey has improved us immensely. We always try to exceed the expectations of our
                                customers by delivering improved products. Excellent Human resources and technology will
                                definitely take us to new heights of success in the coming years. </p>
                            <p> We always give solution to our customer with the help of a variety of products. Our customer always benefits from the rich
                                experience which we have gained by working in different application covering different industry
                                verticals. The customer has an advantage while working with us as all the product varieties are
                                available under one roof. I hereby request you to be part of our journey of excellence.</p>

                            <p>Thank you.</p>

                            <p>Mayur Prakash Pawar<br />
                                (Chairman and founder)</p>
                        </div>
                        <div className="col-md-5">
                            <img src={Mayur} className="w-100" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="joureny-section graybg">
                <div className="container text-center">

                    <h2 className="fw-bold text-center">We believe in making our customers a winner.</h2><br />

                    <p className="fw-bold">Till date we have supplied 25,000+ different types of gearbox.</p>
                    <p>  The ever-increasing Gpro family is stronger by 600+ customers panned across 25 states in India and across 3 continents worldwide.<br />

                        We are continually improving ourselves to cater to the ever-changing demands of the market with lean and greenways.<br />
                        We hope you will continue your support and together we will have a wonderful journey.
                    </p>

                    <div className="journey-highlight mt-5">
                        <div className="list">
                            <div className="sec-head">Year of Establishment</div>
                            <div className="sec-value">2007</div>
                        </div>
                        <div className="list">
                            <div className="sec-head">Area</div>
                            <div className="sec-value">10,000</div>
                            <div className="sec-head">Sq. Feet</div>
                        </div>
                        <div className="list">
                            <div className="sec-head">Gearbox Supplied</div>
                            <div className="sec-value">25,000+</div>
                        </div>
                        <div className="list">
                            <div className="sec-head">Export</div>
                            <div className="sec-value">Yes</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-section  p-0">
                <div className="container">
                    <img src={team} className="w-100" alt="Team" />
                </div>
            </section>
            <section className="excellence-section">
                <div className="container">
                    <h2 className="inner-title mb-5 pb-3">Three pillars of Excellence</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={company} className="w-100" alt="Company Image" />
                        </div>
                        <div className="col-md-6 align-items-center d-flex" style={{ background: '#000' }}>
                            <ul className="highlight-points">
                                <li>
                                    <div className="tile-icon">
                                        <img src={quality} alt="Quality" />
                                    </div>
                                    <div className="desc">
                                        <div className="title">Quality Control</div>
                                        <p className="desc">To achieve CUSTOMER SATISFACTION by providing unmatched QUALITY product
                                            through continuous improvement</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="tile-icon">
                                        <img src={mission} alt="Quality" />
                                    </div>
                                    <div className="desc">
                                        <div className="title">Mission</div>
                                        <p className="desc">We will build VALUE based organization which will SATISFY employees ,
                                            supplier and customers by protecting ENVIRONMENT with emphasis on CLEANLINESS.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="tile-icon">
                                        <img src={vision} alt="vision" />
                                    </div>
                                    <div className="desc">
                                        <div className="title">Vision</div>
                                        <p className="desc">
                                            - To become known GLOBAL brand.<br />
                                            - To become most ADMIRED company<br />
                                            - To Social cause Contributor.
                                        </p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="iso-section graybg">
                <div className="container">
                    <h2 className="inner-title mb-5 pb-3">Our Certification</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="list-dot">
                                <li>ISO 9001:2015 Certified Company.</li>
                                <li>Certifying Agency : TUV Nord.</li>
                                <li>Quality is way of life at GPRO Drives and the certification body helps us to make sure that we deliver value to the customer. </li>
                                <li>Inward, In-process & Outward Inspections are perfectly mapped to provide desired output with the help of training and technology.</li>
                                <li>All important machining processes are carried out in the house.</li>
                                <li>Internal quality audits are carried out by trained in-house staff.</li>
                                <li>Quality control delivers the reliable products to the customer.</li>
                                <li>Component traceability is guaranteed throughout the process cycle.</li>
                                <li>Final test is performed on 100% of products before dispatching to the customer.</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={ISO} className="w-100" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Aboutus; 