import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet'



function Careers() {
    const [show, setShow] = useState(false);


    const onFormSubmit = async (e) => {
        e.preventDefault()
        // const formData = new FormData(e.target),
        //     formDataObj = Object.fromEntries(formData.entries())
        // await axios.post("http://localhost/gpro/api/career.php",formDataObj)
    }

    return (
        <>
         <Helmet>
                <title>GPRO | Careers </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="inner-hero">
                <div className="container">
                    <h1>CAREERS AT GPRO</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </section>
            {
    show && 
    <Alert variant="success">
      <Alert.Heading>Thanks for being awesome!</Alert.Heading>
      <p>
       We have received your message and would like to thank you for writing to us. ...
      </p>     
     
    </Alert>
}
            <section className="ceo-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Form onSubmit={onFormSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">                                    
                                    <Form.Control type="text" placeholder="Name" name="name" />                                   
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">                                    
                                    <Form.Control type="email" placeholder="Email"  name="email"/>                                   
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">                                    
                                    <Form.Control type="number" placeholder="Contact Number" name="mobile" />                                   
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">                                    
                                    <Form.Control type="text" placeholder="Role" name="role" />                                   
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">                                    
                                    <Form.Control type="file" placeholder="Upload Resume" name="file" />                                   
                                </Form.Group>
                                
                                <Button variant="primary" type="submit">
                                    Send
                                </Button>
                            </Form>

                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Careers; 