import React from "react";
import { Link } from "react-router-dom";
// import {facebook, footerlogo, instagram, Youtube} from '../assets/img';
import facebook from '../assets/img/facebook.svg';
import instagram from '../assets/img/instagram.svg';
import youtube from '../assets/img/youtube.svg';
import linkedin from '../assets/img/linkedin.svg';
import footerlogo from '../assets/img/footer-logo.svg';


function Footer() {
    return (
        <>
            <footer className="text-lg-start text-muted footer-section">
                <section className="">
                    <div className="container text-left">
                        <div className="row mt-3">
                            <div className="col-md-4 mx-auto mb-4">
                                <div className="text-uppercase fw-bold mb-4">
                                    <img src={footerlogo} alt="footer logo" />

                                </div>
                                <p>
                                    The ever-increasing Gpro family
                                    is stronger by 600+ customers
                                    panned across 25 states in India
                                    and across 3 continents worldwide.
                                </p>
                                <div className="social-icons">
                                    <Link to="/" className="me-4 text-reset">
                                        <img src={youtube} alt="Youtube" />
                                    </Link>
                                    <a href="https://www.facebook.com/gpro.drives" className="me-4 text-reset">
                                    <img src={facebook} alt="facebook" />

                                    </a>
                                    <a href="https://www.linkedin.com/in/gpro-drives-0997a5210/?original_referer=http%3A%2F%2Fgpro.in%2F"
                                        className="me-4 text-reset">
                                        <img src={linkedin} alt="linkedin" />

                                    </a>
                                    <a href="https://gproindia.com/#" className="me-4 text-reset">
                                        <img src={instagram} alt="instagram" />

                                    </a>
                                </div>
                            </div>



                            <div className="col-md-4 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <Link to="/aboutus" className="text-reset">About Us</Link>
                                </p>
                                <p>
                                    <Link to="/careers" className="text-reset">Careers</Link>
                                </p>
                                <p>
                                    <Link to="/" className="text-reset">Product Gallery</Link>
                                </p>
                                <p>
                                    <Link to="/download-catalouge" className="text-reset">Download Catalogue</Link>
                                </p>
                                <p>
                                    <Link to="/enquiry" className="text-reset">Enquiry</Link>
                                </p>
                                <p>
                                    <Link to="/contactus" className="text-reset">Contact Us</Link>
                                </p>
                                <p>
                                    <Link to="/sitemap" className="text-reset">Site Map</Link>
                                </p>
                            </div>

                            <div className="col-md-4  mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Get In Touch With Us</h6>
                                <p>Contact Us</p>
                                <p className="fg-white m-0">+91 902-882-8728</p>
                                <p className="fg-white m-0 "><a href="mailto:gprodrives@yahoo.com"
                                    className="text-white">gprodrives@yahoo.com</a></p>
                                <p className="fg-white m-0"><a href="mailto:sales@gpro.in" className="text-white">sales@gpro.in</a></p>
                                <p>&nbsp;</p>
                                <p>Our Address</p>
                                <p className="fg-white m-0">M63, Additional MIDC, </p>
                                <p className="fg-white m-0">Satara - 415 004,</p>
                                <p className="fg-white m-0">Maharashtra, India</p>
                            </div>
                        </div>

                    </div>
                </section>

                <div className="p-4 border-1 footer-border">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-start">
                                © 2022 GPRO DRIVES. All rights reserved.
                            </div>
                            <div className="col-md-6 text-end">
                                Career | Faq's | Contact Us
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer; 