import React, { useRef, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/be-product-03.jpeg';
import pa2 from '../assets/img/products/Bevel-Planetary-Gearbox-04-img.jpeg';
import pa from '../assets/img/products/Bevel-Planetary-Gearbox-img-1.png';



function BevelPlanetarygearbox() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <Helmet>
                <title>GPRO | Bevel Planetary Gearbox </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <section className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Bevel Planetary Gearbox</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-5">
                            <Swiper
                                style={{
                                    "--swiper-navigation-color": "#fff",
                                    "--swiper-pagination-color": "#fff",
                                    "height": "600px",
                                }}
                                loop={true}
                                zoom={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[Thumbs, Zoom, EffectCube]}
                                className="mySwiper2"
                                cubeEffect={{
                                    shadow: true,
                                    slideShadows: true,
                                    shadowOffset: 20,
                                    shadowScale: 0.94,
                                }}
                            >
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa1} />
                                    </div>
                                    <div className="caption">
                                        <h4>1. Mounting - Foot</h4>
                                        <p>Input: Hollow, Orientation : B3</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-zoom-container">
                                        <img src={pa2} />
                                    </div>
                                    <div className="caption">
                                        <h4>2. Mounting - Flange</h4>
                                        <p>Input: Hollow, Orientation : B3</p>
                                    </div>
                                </SwiperSlide>


                            </Swiper>

                        </div>
                        <div className="col-md-7">
                            <div className="product-shortinfo">
                                <h4>Gearbox</h4>
                                <h1>Bevel Planetary Gearbox</h1>
                                <h5 className="mt-4">Different Models</h5>
                                <p>The products we offer are durable with a long lasting functional life. The quality of raw materials we use for manufacturing these products are par excellence, ensuring products with optimum quality.</p>
                            </div>
                            <div >
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    loop={true}
                                    spaceBetween={10}
                                    slidesPerView={0}
                                    // freeMode={true}
                                    // watchSlidesProgress={true}
                                    modules={[Thumbs]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa1} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={pa2} />
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                        </div>
                    </div>



                </div>

            </section>


            <section className="product-desc graybg">
                <div className="container">
                    <Tabs
                        defaultActiveKey="Description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Description" title="Description">
                            <h2 className="inner-title mt-5 pb-3 text-start">Leading Planetary Gearbox Manufacturers In India</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={pa} className="w-100" alt="" />
                                </div>
                                <div className="col-md-8">
                                    <p>Our company is a celebrated name in the Bevel planetary gearbox manufacturers.</p>

                                    <p>These are the most suitable amalgam or combination of a bevel gearbox and a planetary gearbox.</p>

                                    <p>These categories of gearboxes are used for transmitting power at 90 degrees with high efficiency.</p>

                                    <p>They come in mounting types like shaft mounting, foot mounting and flange mounting.</p>

                                    <p>And with our skilled professionals by our side, the products we come up with are most of the times free from any defects.</p>

                                    <p>Moreover, we offer these gearboxes at a very affordable price as well.</p>
                                </div>
                            </div>
                            <h2 className="inner-title mt-5 pb-3 text-start">Features of these Bevel Planetary Gearboxes                    </h2>
                            <div className="row">

                                <div className="col-md-8">
                                    <p>Let us have a look at some of the product specifications and features of these
                                        <span className="fw-bold">Bevel Planetary Gearbox:</span>
                                    </p>
                                    <ul className="list-dot">
                                        <li>Mounted at 90 degree with each other</li>
                                        <li>Arrangement of through and through hollow shafting</li>
                                        <li>Possess a considerably high reduction ratio</li>
                                        <li>Show superlative performances</li>
                                        <li>A long lasting functional life</li>
                                        <li>The input power they require varies from fractional HP to 500 HP</li>
                                        <li>Support torque of range: 4kgm to 45000kgm</li>
                                        <li>Ratio of 3:1 to 15000:1</li>
                                    </ul>

                                </div>
                                <div className="col-md-4">
                                    <img src={pa} className="w-100" alt="" />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Technical Specifications" title="Technical Specifications">
                            <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                            <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack, Bevel Drives, Worm Drives,</span>
                                and etc.</p>
                            <div className="technicalinfo mt-4">
                                <div className="list">
                                    <div className="info-head">
                                        Mounting
                                    </div>
                                    <div className="info-desc">
                                        Foot / Flange/ Agitator
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Input</div>
                                    <div className="info-desc">Hollow to suite Electric / Hydraulic Motor<br />
                                        Male Free Shaft (For Mounting Coupling, Pulley, Sprocket etc.)</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Orientation</div>
                                    <div className="info-desc">
                                        B3 (Horizontal)<br />
                                        V5 (Vertically Downward)<br />
                                        V6 (Vertically Upward)
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Efficiency</div>
                                    <div className="info-desc">96% per stage</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ambient Temp.</div>
                                    <div className="info-desc">40 Deg. C</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Allowable Temperature Rise</div>
                                    <div className="info-desc">45 Deg. C above ambient temp.</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ratio Range</div>
                                    <div className="info-desc">From 3.63:1 Upto customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Output Torque Range</div>
                                    <div className="info-desc">From 12 Kg.m To 60,000 Kg.m</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Input Power</div>
                                    <div className="info-desc">From 0.16 Hp/0.12kW To 500 Hp/375kW</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Max. Input Speed</div>
                                    <div className="info-desc">1440 rpm (For Higher / Lower Speed Consult GPRO)</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Paint Shade</div>
                                    <div className="info-desc">STD : RAL Blue<br />
                                        SPL : Special color can be provided against specific customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Method of Cooling</div>
                                    <div className="info-desc">STD : Splash Type Lubrication air cooled<br />
                                        SPL : Forced Cooling can be provided against specific customer requirement
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Lubrication</div>
                                    <div className="info-desc">
                                        For Model 501 Gearbox is filled with Grease<br />
                                        ISO VG 220/320 Synthetic Base Oil<br />
                                        STD : Gearbox supplied without oil<br />
                                        SPL : Gearbox supplied with oil against specific customer requirement <br />
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Standard Accessories</div>
                                    <div className="info-desc">For Orientation V5 & V6 Oil Cup Assembly will be provided</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Applicable Standards</div>
                                    <div className="info-desc">AGAM , ISO, IS</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Advantage</div>
                                    <div className="info-desc">Input & Output are at right angle to each other hence occupies less length</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Disadvantage</div>
                                    <div className="info-desc">Costly compare to pure Planetary</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Application</div>
                                    <div className="info-desc">Material handling, Chemical, Sugar, Cement,Paper, Rubber, Marine, Food Industry, Automation, Oil & Gas, Textile and much more</div>
                                </div>

                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </section>
        </>
    );
}

export default BevelPlanetarygearbox; 