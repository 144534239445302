import React from "react";
import { Helmet } from 'react-helmet'

import pa1 from './assets/img/applications/aggg/agriculture.jpeg';
import pa3 from './assets/img/applications/aggg/cement_plant.jpeg';
import pa5 from './assets/img/applications/aggg/chem-plant.jpeg';
import pa7 from './assets/img/applications/aggg/forestry.jpeg';
import pa9 from './assets/img/applications/aggg/marine.jpeg';
import pa11 from './assets/img/applications/aggg/material-handling.jpeg';
import pa13 from './assets/img/applications/aggg/mining.png';
import pa15 from './assets/img/applications/aggg/sugarplant.jpeg';
import pa16 from './assets/img/applications/sugarplant.png';
import pa14 from './assets/img/applications/mining.png';
import pa12 from './assets/img/applications/material-handling.png';
import pa10 from './assets/img/applications/marine.png';
import pa8 from './assets/img/applications/forestry.png';
import pa6 from './assets/img/applications/chem-plant.png';
import pa4 from './assets/img/applications/cement_plant.png';
import pa2 from './assets/img/applications/agriculture.png';
import zoom from './assets/img/zoom.svg';


function Applications() {;

    return (
        <>
<Helmet>
                <title>GPRO | Applicattion </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
        
        
            <section className="inner-hero">
                <div className="container">
                    <h1>Applications</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Applications</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className="app-innerSection">
                <div className="container">
                    <div className="row">
                        <a data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa2} className="img-fluid content-image" />
                                <div className="content-details fadeIn-bottom">
                                    {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Agriculture</h3>
                                </div>
                        </a>
                        <a  data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa4} className="img-fluid content-image" />
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Cement Plant</h3>
                                </div>
                        </a>
                        <a  data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa6} className="img-fluid content-image"/>
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Chemical Plant</h3>
                                </div>
                        </a>
                        <a data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa8} className="img-fluid content-image" />
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Forestry</h3>
                                </div>
                        </a>
                        <a  data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa10} className="img-fluid content-image"/>
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Marine</h3>
                                </div>
                        </a>
                        <a  data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa12} className="img-fluid content-image" />
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Material Handling</h3>
                                </div>
                        </a>
                        <a  data-toggle="lightbox" data-gallery="example-gallery" className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src= {pa14} className="img-fluid content-image" />
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Mining</h3>
                                </div>
                        </a>
                        <a  className="col-sm-4 thumbnail-img">
                            <div className="content-overlay"></div>
                            <img src={pa16} className="img-fluid content-image" />
                                <div className="content-details fadeIn-bottom">
                                   {/* <img src={zoom} alt="Zoom" className="zoom-icon" /> */}
                                        <h3 className="content-title">Sugar Plant</h3>
                                </div>
                        </a>

                    </div>
                </div>
            </section>        </>
    );
}

export default Applications; 