import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import balco from './assets/img/home/balco.png';
import mukund from './assets/img/home/mukund.png';
import srg from './assets/img/home/srg.png';
import mhps from './assets/img/home/mhps.png';
import security from './assets/img/home/security.png';
import bhel from './assets/img/home/bhel.png';
import force from './assets/img/home/force.png';
import emerson from './assets/img/home/emerson.png';
import upl from './assets/img/home/upl.png';
import rpsanjiv from './assets/img/home/rp-sanjiv.png';
import andritz from './assets/img/home/andritz.png';
import capacite from './assets/img/home/capacite.png';
import gaar from './assets/img/home/gaar.png';
import jindal from './assets/img/home/jindal.png';

import { Navigation, Autoplay } from "swiper";

function Customer() {

    return (
        <>
            <section className="customer-section">
                <div className="container">

                    <h3 className="home-subtitle">Our Valuable</h3>
                    <h2 className="home-title">Customer</h2>
                    <div className="swiper-container mySwiperCustom">
                        <Swiper
                            slidesPerView={5}
                            spaceBetween={70}
                            grabCursor={true}
                            navigation={true}
                            modules={[Navigation, Autoplay]}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: true,
                              }}
                              breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                  },
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 5,
                                  spaceBetween: 50,
                                },
                              }}
                            className="mySwiper"
                        >
                            <SwiperSlide><img src={balco}/></SwiperSlide>
                            <SwiperSlide><img src={mukund}/></SwiperSlide>
                            <SwiperSlide><img src={srg}/></SwiperSlide>
                            <SwiperSlide><img src={mhps}/></SwiperSlide>
                            <SwiperSlide><img src={security}/></SwiperSlide>
                            <SwiperSlide><img src={bhel}/></SwiperSlide>
                            <SwiperSlide><img src={force}/></SwiperSlide>
                            <SwiperSlide><img src={upl}/></SwiperSlide>
                            <SwiperSlide><img src={emerson}/></SwiperSlide>
                            <SwiperSlide><img src={rpsanjiv}/></SwiperSlide>
                            <SwiperSlide><img src={andritz}/></SwiperSlide>
                            <SwiperSlide><img src={capacite}/></SwiperSlide>
                            <SwiperSlide><img src={gaar}/></SwiperSlide>
                            <SwiperSlide><img src={jindal}/></SwiperSlide>
                            
                        </Swiper>





                        {/* <div className="swiper-wrapper">
             import mission from './assets/img/home/balco.png 
             import mission from './assets/img/home/mukund.png 
             import mission from './assets/img/home/srg.png 
             import mission from './assets/img/home/mhps.png 
             import mission from './assets/img/home/security.png 
             import mission from './assets/img/home/bhel.png 
             import mission from './assets/img/home/force.png 
             import mission from './assets/img/home/emerson.png 
             import mission from './assets/img/home/upl.png 
             import mission from './assets/img/home/rp-sanjiv.png 
             import mission from './assets/img/home/andritz.png 
             import mission from './assets/img/home/capacite.png 
             import mission from './assets/img/home/gaar.png 
             import mission from './assets/img/home/jindal.png 
         </div>
         <div className="swiper-arrow">
             <div className="swiper-button-prev"></div>
             <div className="swiper-button-next"></div>
         </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Customer;


