import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";
import { Helmet } from 'react-helmet'


// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Creep-Drive.jpeg';
import pa2 from '../assets/img/products/Creep-Drive.png';



function CreepDrive() {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
        <Helmet>
                <title>GPRO | Creep Drive </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <div className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Creep Drive</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="product-desc graybg">
            <div className="container">
                <Tabs
                    defaultActiveKey="Description"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Description" title="Description">
                        <h2 className="inner-title mt-5 pb-3 text-start">Leading Creep Drive Manufacturers In India

                        </h2>
                        <div className="row">
                            <div className="col-md-4">
                            <img src={pa1} className="w-100" alt="Creep Drive" />
                            </div>
                            <div className="col-md-8">
                                <p>We, the GPRO drive, offer you the finest Creep drives fabricated out of high-grade raw materials.</p>

                                <p>The explicit range of these creep gear drives we offer find extensive use and demand owing to their strength, durability and supreme quality in the markets today.</p>

                                <p>They are manufactured keeping the needs and demands of the customers strictly.</p>

                                <p>These planetary creep drives are used in overhead cranes for the purpose of achieving micro speed.</p>

                                <p>They usually work on the principles of planetary motion with a differential drive system along with thruster brakes.</p>


                            </div>
                        </div>
                        <h2 className="inner-title mt-5 pb-3 text-start">Features of these Creep Drivees</h2>
                        <div className="row">

                            <div className="col-md-8">
                                <p>Let us have a look at some of the product specifications and features of these
                                    <span className="fw-bold">Creep Drive:</span>
                                </p>
                                <ul className="list-dot">
                                    <li>Possess a torque capacity of the range 50 Nm to 1000 Nm.</li>
                                    <li>Reduction ratio is usually 10:1. However, we can still provide you with different ratios on request.</li>
                                    <li>The power rating of usually 0.37 kW to 15 kW</li>
                                    <li>Designed very compactly, and thus are space saving</li>
                                    <li>Mounting is of foot type</li>
                                    <li>The diameters of Brake drums are: 160mm, 200mm, 250mm, 300mm</li>
                                    <li>Good torque capacity and long service life.</li>
                                    <li>Designed compactly and accurately</li>
                                    <li>Offers precision functioning</li>
                                </ul>

                            </div>
                            <div className="col-md-4">
                            <img src={pa2} className="w-100" alt="Creep Drive" />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Technical Specifications" title="Technical Specifications">
                    <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                    <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack, Bevel Drives, Worm Drives,</span>
                        and etc.</p>

                   
                    <div className="technicalinfo mt-4">
                        <div className="list">
                            <div className="info-head">
                                Mounting
                            </div>
                            <div className="info-desc">
                                Foot
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Input</div>
                            <div className="info-desc">Male Free Shaft (For Mounting Coupling, Pulley, Sprocket etc.)</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Orientation</div>
                            <div className="info-desc">
                                B3 (Horizontal)
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Efficiency</div>
                            <div className="info-desc">96%</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Ambient Temp.</div>
                            <div className="info-desc">40 Deg. C</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Allowable Temperature Rise</div>
                            <div className="info-desc">45 Deg. C above ambient temp.</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Ratio Range</div>
                            <div className="info-desc">10:1</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Output Torque Range</div>
                            <div className="info-desc">From 21 Kg.m To 265 Kg.m</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Input Power</div>
                            <div className="info-desc">From 0.5 Hp/0.37kW To 5 Hp/3.75kW</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Max. Input Speed</div>
                            <div className="info-desc">1440 rpm (For Higher / Lower Speed Consult GPRO)</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Paint Shade</div>
                            <div className="info-desc">STD : RAL Blue<br/>
                                SPL : Special color can be provided against specific customer requirement</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Method of Cooling</div>
                            <div className="info-desc">STD : Splash Type Lubrication air cooled<br/>
                                SPL : Forced Cooling can be provided against specific customer requirement</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Lubrication</div>
                            <div className="info-desc">
                                ISO VG 220/320 Synthetic Base Oil<br/>
                                STD : Gearbox supplied without oil<br/>
                                SPL : Gearbox supplied with oil against specific customer requirement
                            </div>
                        </div>
                        <div className="list">
                            <div className="info-head">Standard Accessories</div>
                            <div className="info-desc">Nil</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Applicable Standards</div>
                            <div className="info-desc">AGAM , ISO, IS</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Advantage</div>
                            <div className="info-desc">Nil</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Disadvantage</div>
                            <div className="info-desc">Nil</div>
                        </div>
                        <div className="list">
                            <div className="info-head">Application</div>
                            <div className="info-desc">Used in Overhead Crane Application</div>
                        </div>

                    </div>
                    </Tab>

                </Tabs>
            </div>
            </section>
        </>
    );
}

export default CreepDrive; 