import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";
import { Helmet } from 'react-helmet'


// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Screw-Jack-03-1-big.jpeg';
import pa2 from '../assets/img/products/Screw Jack-2.jpeg';



function Screwjack() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
        <Helmet>
                <title>GPRO | Screw Jack </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <div className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">screw_jack</li>
                        </ol>
                    </nav>
                </div>

            </div>

            <section className="product-desc graybg">
                <div className="container">
                    <Tabs
                        defaultActiveKey="Description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Description" title="Description">
                            <h2 className="inner-title mt-5 pb-3 text-start">Leading Planetary Gearbox Manufacturers In India</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={pa1} className="w-100" alt="" />
                                </div>
                                <div className="col-md-8">
                                    <p>Being one of the most trusted and dedicated manufacturers and suppliers of the lot, our company does justice to its name by providing its clients with <span className="fw-bold">Screw Jacks</span> of supreme quality.
                                    </p><p>They are produced under strict supervision of our professionals and thus use premium quality raw materials, making these products more durable, mechanically strong, precisely functioning, and so on.
                                    </p><p>Influenced by the product’s demand in the markets, we have decided to make it available to the customers in various shapes and sizes requested.
                                    </p><p>Further, we make it a point to process this product through a number of significant checks and procedures, ensuring superlative quality and functionality.
                                    </p><p>Among the other screw jacks, we have expertise in manufacturing <span className="fw-bold">Mechanical Screw jacks</span>.</p>
                                </div>
                            </div>
                            <h2 className="inner-title mt-5 pb-3 text-start">Features of these Screw Jack
                            </h2>
                            <div className="row">

                                <div className="col-md-8">
                                    <p>Let us have a look at some of the product specifications and features of these
                                        <span className="fw-bold">Screw Jack:</span>
                                    </p>
                                    <ul className="list-dot">
                                        <li>Capacity of 1.5 – 20 T
                                        </li><li>Withstand a maximum load of 26 tons
                                        </li><li>Withstand a maximum static load of 100 tons
                                        </li><li>Compact structure
                                        </li><li>Optimum strength
                                        </li><li>Offer a long lasting functional life
                                        </li><li>Highly durable
                                        </li><li>Sufficiently enough high load bearing capacity
                                        </li>                            </ul>

                                </div>
                                <div className="col-md-4">
                                <img src={pa2} style={{maxHeight: 400}} alt="" />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Technical Specifications" title="Technical Specifications">
                            <h2 className="inner-title mt-5 pb-3 text-start">We Give Best Business Solution</h2>
                            <p>It we caters to the needs of our clients by manufacturing, exporting and supplying the various equipment’s such as <span className="fw-bold">Planetary Gearboxes, Hydraulic Winch, Screw Jack,
                                Bevel Drives, Worm Drives,</span>
                                and etc.</p>
                            <div className="technicalinfo mt-4">
                                <div className="list">
                                    <div className="info-head">
                                        Mounting
                                    </div>
                                    <div className="info-desc">
                                        Casing
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Input</div>
                                    <div className="info-desc">Male Free Shaft (For Mounting Coupling, Pulley, Sprocket etc.),<br />
                                        Input Hollow with Bell Housing arrangement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Orientation</div>
                                    <div className="info-desc">
                                        B1 U (Upright), I (Inverted), B3 (Horizontal)
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Efficiency</div>
                                    <div className="info-desc">60% Max</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ambient Temp.</div>
                                    <div className="info-desc">40 Deg. C</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Allowable Temperature Rise</div>
                                    <div className="info-desc">45 Deg. C above ambient temp.</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Ratio Range</div>
                                    <div className="info-desc">8:1, 24:1</div>
                                </div>

                                <div className="list">
                                    <div className="info-head">Lifting Capacity</div>
                                    <div className="info-desc">From 0.5 Ton To 100 Ton</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Lifting Height</div>
                                    <div className="info-desc">From 5 mm To 2000 mm</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Operation Type</div>
                                    <div className="info-desc">Hand Operated, Motorised</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Screw Type</div>
                                    <div className="info-desc">Rotating Screw with Attachment<br />
                                        Translating Nut Type<br />
                                        Translating Screw Type</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Paint Shade</div>
                                    <div className="info-desc">STD : RAL Blue<br />
                                        SPL : Special color can be provided against specific customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Method of Cooling</div>
                                    <div className="info-desc">STD : Splash Type Lubrication air cooled<br />
                                        SPL : Forced Cooling can be provided against specific customer requirement</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Lubrication</div>
                                    <div className="info-desc">
                                        ISO VG 220/320 Synthetic Base Oil<br />
                                        STD : Gearbox supplied without oil<br />
                                        SPL : Gearbox supplied with oil against specific customer requirement
                                    </div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Standard Accessories</div>
                                    <div className="info-desc">Nill</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Optional Accessories</div>
                                    <div className="info-desc">Top Plate,Clavis, Trunion Mounting Plate, Bellow</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Applicable Standards</div>
                                    <div className="info-desc">AGAM , ISO, IS</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Advantage</div>
                                    <div className="info-desc">Compact in Size</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Disadvantage</div>
                                    <div className="info-desc">Nil</div>
                                </div>
                                <div className="list">
                                    <div className="info-head">Application</div>
                                    <div className="info-desc">Used in Overhead Crane Application</div>
                                </div>

                            </div>
                        </Tab>

                    </Tabs>
                </div>
                </section>
        </>
    );
}

export default Screwjack; 