import React, { useRef, useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet'


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "swiper/css/effect-cube";

// import { Zoom, Navigation, Thumbs } from "swiper";
import { FreeMode, Navigation, Thumbs, Zoom, EffectCube } from "swiper";
import "swiper/css/bundle";

import pa1 from '../assets/img/products/Winches.jpeg';
import pa2 from '../assets/img/products/Winch_Drive.png';



function WinchDrive() {


    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
        <Helmet>
                <title>GPRO | Winch Drive </title>
                <meta name="description" content="We are leading industrial gearbox manufacturers in India, Pune, Satara offering Planetary Gearboxes in modular design, compact, high torque transmitting and all" />
                <meta name="description" content="Gearbox manufacturer, 
Industrial Gearbox Manufacturer, Planetary gearbox,  worm planetary gearbox,  bevel planetary gearbox,  bevel gearbox,  worm gearbox,  electric winch, hydraulic winch, creep drive, track drive, hoist, screw jack, crystaliser drive, planetary mixer drives" />
            </Helmet>
            <div className="pt-4 product-head">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Product</li>
                            <li className="breadcrumb-item active" aria-current="page">Winch Drive</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="product-desc graybg">
                <div className="container">
                    <Tabs
                        defaultActiveKey="Description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Description" title="Description">
                        
                <h2 className="inner-title mt-5 pb-3 text-start">Leading Winch Drive (WD) Manufacturers In India</h2>
                <div className="row">
                    <div className="col-md-4">
                    <img src={pa1} className="w-100" alt="" />
                    </div>
                    <div className="col-md-8">
<p>We are a renowned name in the markets today.
</p><p>Our products are above excellence, customers say.
</p><p>And the <span className="fw-bold">Winch Drive</span> we manufacture are no different.
</p><p>They can be of many types. Suitable for shaft sinking, both the <span className="fw-bold">Electric winch</span> and <span className="fw-bold">Hydraulic winch</span> we manufacture are worth giving a shot.
</p><p>These are extensively used for loading, unloading, and pulling heavy loads at variable speeds.
</p><p>These winches are manufactured under the strict supervision of professionals to keep a check of the quality of the raw materials used.
</p><p>These are thus bestowed with different features like their resistance to corrosion, sturdy construction, easy maintenance and hassle free operation.
</p>

                    </div>
                </div>
                <h2 className="inner-title mt-5 pb-3 text-start">Features of these Winch Drive (WD)</h2>
                <div className="row">

                    <div className="col-md-8">
                        <p>Let us have a look at some of the product specifications and features of these
                            <span className="fw-bold">Winch Drive (WD):</span>
                        </p>
                        <ul className="list-dot">
                            <li>Designed compactly, thus saving a lot of space
                            </li>
                            <li>Highly durable
                            </li>
                            <li>Considerably low weight
                            </li>
                            <li>Offers superlative performances
                            </li>
                            <li>Extensive use in pulling or lifting applications at different speeds
                            </li>
                            <li>Capacity of 1 ton up to 100 ton
                            </li>
                            <li>Robustly constructed and properly cased
                            </li>
                            <li>Require a considerably low maintenance
                            </li>
                        </ul>

                    </div>
                    <div className="col-md-4">
                    <img src={pa2} className="w-100" alt="" />
                    </div>
                </div>
                        </Tab>
                      

                    </Tabs>
                </div>
                </section>
        </>
    );
}

export default WinchDrive; 